import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { SMS as SMSData } from './data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class SMSMockApi {
    private _SMS: any[] = SMSData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Pacs - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/sms')
            .reply(() => {
                // Clone the Exam
                const sms = cloneDeep(this._SMS);
                return [200, sms];
            });
    }
}
