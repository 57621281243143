export const bodyParts = [
    {
        "id": "5965df7b-cbc5-4d81-af96-7ff8dd49ae0e",
        "title": "ABC",
        "modality_time": "30",
        "order": 0,
        "status": "AC",
        "cpt_code": "ABC"
    },
    {
        "id": "5965df7b-cbc5-4d81-af96-7ff8dd49ae0e",
        "title": "ABC",
        "modality_time": "30",
        "order": 0,
        "status": "AC",
        "cpt_code": "ABC"
    },
    {
        "id": "5965df7b-cbc5-4d81-af96-7ff8dd49ae0e",
        "title": "US DOPPLER LOWER EXTREMITY VENOUS - BILATERAL",
        "modality_time": "30",
        "order": 0,
        "status": "NA",
        "cpt_code": "93970"
    },
    {
        "id": "5965df7b-cbc5-4d81-af96-7ff8dd49ae0e",
        "title": "X-RAY HIPS BILATERAL with PELVIS 3-4 VIEWS",
        "modality_time": "30",
        "order": 0,
        "status": "NA",
        "cpt_code": "73522"
    }
]