import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { UserService } from 'app/core/user/user.service';
import { User } from 'app/core/user/user.types';
import { Notification, NotificationResponse } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    private _userService = inject(UserService);
    private _user: User;
    private _baseUrl = environment.apiURL;
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
        this._userService.user$.subscribe(user => {
            this._user = user;
        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<NotificationResponse> {
        return this._httpClient.get<NotificationResponse>(`${this._baseUrl}notification/list/${this._user.id}/`).pipe(
            tap((notifications) => {
                this._notifications.next(notifications.results);
            }),
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
                map((newNotification) => {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<Notification>('api/common/notifications', {
                id,
                notification,
            }).pipe(
                map((updatedNotification: Notification) => {
                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                }),
            )),
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', { params: { id } }).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }

    /**
     * Mark selected notifications as read or archive
     */
    markAsReadOrArchiveSelectedNotifications(selectedNotifications: Notification[], action: 'archive' | 'read'): Observable<boolean> {
        const selectedNotificationIds = selectedNotifications.map(n => n.id);
        const payload = {
            ids: selectedNotificationIds
        };
        payload[action] = true;

        return this.notifications$.pipe(
            take(1),
            tap((notifications) => {
                notifications.forEach((notification) => {
                    if (selectedNotificationIds.includes(notification.id)) {
                        notification[action] = true;
                    }
                });

                notifications = notifications.map(notification => {
                    notification.selected = false;
                    return notification;
                });
                this._notifications.next(notifications);
            }),
            switchMap(notifications => this._httpClient.post<boolean>(`${this._baseUrl}notification/update/`, payload).pipe(
                map((isUpdated: boolean) => {
                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }
}
