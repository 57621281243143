<div class="flex flex-col text-white max-h-[90vh] overflow-y-auto">
  <div class="p-6 flex items-center justify-between border-b border-[#333844]">
    <div class="text-xl font-bold font-host">
      Choose a workspace
    </div>
    <button mat-icon-button (click)="dialog.closeAll()" class="w-5 min-w-5 h-5 min-h-5">
      <mat-icon class="text-white cursor-pointer icon-size-5">close</mat-icon>
    </button>
  </div>

  <div class="p-6 flex flex-col gap-8 max-h-[90vh-166px] overflow-y-auto">
    <div>
      <div class="text-lg font-normal font-host text-[#9297A7] mb-4">
        Workspaces for <span class="text-white">{{ userData.email }}</span>
      </div>

      <div class="workspace-tabstyleWrap">
        <mat-tab-group selectedIndex="0">
          <mat-tab label="Existing">
            <ng-template matTabContent>
              <div class="overflow-y-auto max-h-[189px]">
                @for (item of userData.workspaces; track $index) {
                <div class="px-2 rounded-lg cursor-pointer" *ngIf="item.status === 'AC'"
                  [ngClass]="{'bg-[#2F3646]': item.workspace_id === selectedWorkspace?.workspace_id}"
                  (click)="onSelectionChange(item)">
                  <div class="flex items-center gap-3 py-2 text-white font-host border-b border-[#333844]">
                    <div class="w-4 h-4">
                      <mat-checkbox [checked]="item.workspace_id === selectedWorkspace?.workspace_id"
                        (ngModelChange)="onSelectionChange(item)"></mat-checkbox>
                    </div>

                    <div [ngStyle]="{'background-color': item?.bgColor}"
                      class="min-w-[43px] h-[43px] rounded-lg text-xl font-medium flex items-center justify-center">
                      {{ item.workspace[0] | uppercase }}
                    </div>

                    <div class="flex flex-col gap-1">
                      <div class="text-lg font-medium">{{ item.workspace }}</div>

                      <div class="flex items-center gap-2">
                        <div class="flex flex-0 items-center -space-x-1.5">
                          <img *ngFor="let imgLink of [1, 2, 3, 4]"
                            class="flex-0 w-4 h-4 rounded-full ring-1 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="/assets/icons/user-profile.svg">
                        </div>

                        <div class="text-sm font-normal text-[#9297A7]">
                          {{ item.members }} members
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              Pending
              <div class="w-3 h-3 absolute -right-3 top-0
                rounded-full bg-[#FF6B00] text-[8px] font-host text-white flex items-center justify-center leading-3">
                {{pendingWorkspaceCount}}
              </div>
            </ng-template>

            <ng-template matTabContent>
              <div class="overflow-y-auto px-2 text-white font-host max-h-[189px]">
                @for (item of userData.workspaces; track $index) {
                <div class="flex items-center justify-between py-2 border-b border-[#333844]"
                  *ngIf="item.status === 'PD'">
                  <div class="flex items-center gap-3">
                    <div [ngStyle]="{'background-color': item?.bgColor}"
                      class="min-w-[43px] h-[43px] rounded-lg text-xl font-medium flex items-center justify-center">
                      {{ item.workspace[0] | uppercase }}
                    </div>

                    <div class="flex flex-col gap-1">
                      <div class="text-lg font-medium"> {{ item.workspace }}</div>

                      <div class="flex items-center gap-2">
                        <div class="flex flex-0 items-center -space-x-1.5">
                          <img *ngFor="let imgLink of [1, 2, 3, 4]"
                            class="flex-0 w-4 h-4 rounded-full ring-1 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="/assets/icons/user-profile.svg">
                        </div>

                        <div class="text-sm font-normal text-[#9297A7]">
                          {{ item.members }} members
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex gap-2">
                    <button mat-button (click)="decline(item)"
                      class="text-[#20AFFF] border border-solid border-[#20AFFF] font-bold text-base rounded-lg px-5 font-host">
                      Decline
                    </button>
                    <button mat-button (click)="accept(item)"
                      class="text-[#ffffff] bg-[#20AFFF] font-bold text-base rounded-lg px-5 font-host">
                      Accept
                    </button>
                  </div>
                </div>
                }
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>

  <!-- <div class="p-6 flex flex-col gap-8 max-h-[90vh-166px] overflow-y-auto">
    @for (workspace of workspaceData; track $index) {
    <div>
      <div class="text-lg font-normal font-host text-[#9297A7] mb-4">
        Workspaces for <span class="text-white">{{ workspace.email }}</span>
      </div>

      <div class="workspace-tabstyleWrap">
        <mat-tab-group selectedIndex="0">
          <mat-tab label="Existing">
            <ng-template matTabContent>
              <div class="overflow-y-auto max-h-[189px]">
                @for (item of workspace.workspaces; track $index) {
                <div class="px-2 rounded-lg cursor-pointer"
                  [ngClass]="{'bg-[#2F3646]': item.id === this.selectedWorkspace?.id}" (click)="onSelectionChange(item)">
                  <div class="flex items-center gap-3 py-2 text-white font-host border-b border-[#333844]">
                    <div class="w-4 h-4">
                      <mat-checkbox [checked]="item.id === this.selectedWorkspace?.id"
                        (ngModelChange)="onSelectionChange(item)"></mat-checkbox>
                    </div>

                    <div [ngStyle]="{'background-color': item?.bgColor}"
                      class="min-w-[43px] h-[43px] rounded-lg text-xl font-medium flex items-center justify-center">
                      {{ item.name[0] }}
                    </div>

                    <div class="flex flex-col gap-1">
                      <div class="text-lg font-medium">{{ item.name }}</div>

                      <div class="flex items-center gap-2">
                        <div class="flex flex-0 items-center -space-x-1.5">
                          <img *ngFor="let imgLink of item.profileImages"
                            class="flex-0 w-4 h-4 rounded-full ring-1 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="assets/icons/{{imgLink}}">
                        </div>

                        <div class="text-sm font-normal text-[#9297A7]">
                          {{ item.members }} members
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab>
            <ng-template mat-tab-label>
              Pending
              <div class="w-3 h-3 absolute -right-3 top-0
                rounded-full bg-[#FF6B00] text-[8px] font-host text-white flex items-center justify-center leading-3">
                2
              </div>
            </ng-template>

            <ng-template matTabContent>
              <div class="overflow-y-auto px-2 text-white font-host max-h-[189px]">
                <div class="flex items-center justify-between py-2 border-b border-[#333844]"
                  *ngFor="let item of [0, 1]">
                  <div class="flex items-center gap-3">
                    <div [ngStyle]="{'background-color': '#C27803'}"
                      class="min-w-[43px] h-[43px] rounded-lg text-xl font-medium flex items-center justify-center">
                      C
                    </div>

                    <div class="flex flex-col gap-1">
                      <div class="text-lg font-medium">Carewell</div>

                      <div class="flex items-center gap-2">
                        <div class="flex flex-0 items-center -space-x-1.5">
                          <img *ngFor="let imgLink of [1, 2, 3, 4]"
                            class="flex-0 w-4 h-4 rounded-full ring-1 ring-offset-1 ring-gray-800 ring-offset-gray-800 object-cover"
                            src="/assets/images/avatars/male-02.jpg">
                        </div>

                        <div class="text-sm font-normal text-[#9297A7]">
                          34 members
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex gap-2">
                    <button mat-button
                      class="text-[#20AFFF] border border-solid border-[#20AFFF] font-bold text-base rounded-lg px-5 font-host">
                      Decline
                    </button>
                    <button mat-button (click)="accept()"
                      class="text-[#ffffff] bg-[#20AFFF] font-bold text-base rounded-lg px-5 font-host">
                      Accept
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    }
  </div> -->

  <div class="p-6 border-t border-[#333844] flex justify-end space-x-3">
    <button mat-button (click)="dialog.closeAll()"
      class="text-[#20AFFF] border border-solid border-[#20AFFF] font-bold text-base rounded-lg px-5 font-host">
      Cancel
    </button>
    <button mat-button (click)="open()"
      class="text-[#ffffff] bg-[#20AFFF] font-bold text-base rounded-lg px-5 font-host disabled:bg-[#242837] disabled:text-[#626778] ease-in-out duration-150"
      [disabled]="!selectedWorkspace">
      Open
    </button>
  </div>
</div>