import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { userActivityLog as UserActivityLogData } from './data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class UserActivityMockApi {
    private _userActivityLog: any[] = UserActivityLogData;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ Pacs - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService
            .onGet('api/apps/useractivitylog')
            .reply(() => {
                // Clone the Exam
                const pacs = cloneDeep(this._userActivityLog);
                // Sort the Pacs alphabetically by id
                pacs.sort((a, b) => a.id.localeCompare(b.id));
                return [200, pacs];
            });
    }
}
