<div class="w-full bg-darkprimary-100  h-full  overflow-auto">
    <div class="flex flex-wrap h-full relative">
        <div class="max-w-80 w-full h-full bg-darkprimary-300 relative   ">
            <div class="border-r border-solid border-darkprimary-50 px-10 py-8 h-full">
                <div>
                    <p class="font-clash font-medium text-3xl leading-9 text-white">Shared
                        Studies({{this.studyList.length}})</p>
                    <div class="flex items-center justify-between py-2">
                        <button mat-button
                            class="h-10 w-[calc((100%/2)-10px)]  border border-solid border-textprimary-300 rounded-lg p-2 font-bold font-host text-white flex items-center justify-center min-h-max min-w-max"
                            (click)="prevStudy()" [disabled]="currentIndex == 0"
                            [ngClass]="{'opacity-50': currentIndex == 0}">Prev Study</button>
                        <button
                            class="h-10  w-[calc((100%/2)-10px)]  border border-solid border-textprimary-300 rounded-lg p-2 font-bold font-host text-white flex items-center justify-center min-h-max min-w-max"
                            (click)="nextStudy()" [disabled]="currentIndex == this.studyList.length-1"
                            [ngClass]="{'opacity-50': currentIndex == this.studyList.length-1}">Next Study</button>
                    </div>
                </div>
                <div class="mb-5 flex items-center justify-between mt-3">
                    <p class="font-clash font-medium text-3xl leading-9 text-white">Study Details</p>
                </div>
                <div class="-m-2 max-h-[calc(100vh-199px)] overflow-y-auto">
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Patient</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.patient_name}}
                        </p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Gender</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.gender}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Dob</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.dob ? moment(studyDetails?.dob).format('MM/DD/YYYY') : ''}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">MRN</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.mrn}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">MOD</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.modality}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Exam</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.exam}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Assignee</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.assignee ?
                            studyDetails?.assignee : 'NA'}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Referrer</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.referrer ?
                            studyDetails?.referrer : 'NA'}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">DOS</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.dos ? moment(studyDetails?.dos).format('MM/DD/YYYY') : ''}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Time</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.time}}</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Images</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.image_count}}
                            Images</p>
                    </div>
                    <div class="p-2">
                        <p class="text-xl text-white font-clash leading-5 mb-1 font-semibold">Reports</p>
                        <p class="text-md text-white font-clash leading-5 font-normal">{{studyDetails?.report_count}}
                            Reports</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="max-w-[calc(100%-320px)] w-full  relative">

            <div class="max-w-[1080px] px-5 mx-auto">

                <div class="py-8 w-full">
                    <div class="mb-6 pb-4 border-b border-solid border-darkprimary-50">
                        <h1 class="font-clash font-medium text-4xl leading-10 text-white">Study Images</h1>
                    </div>

                    <div class="my-5">
                        <div class="h-[calc(100vh-240px)] overflow-y-auto">
                            <iframe frameborder="0" allowfullscreen [src]="viewerUrl" id="dicomViewer" scrolling="no"
                                frameborder="0" style="position: relative; height: 100%; width: 100%;"></iframe>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>