export enum NotificationTypeEnum {
    APPOINTMENT = "appointment",
    EXAM = "exam",
    REPORT = "report",
    USER = "user",
    PATIENT = "patient",
    LOCATION = "location"
}

export interface NotificationType {
    type?: string
    meta_data?: string
}

export interface Notification {
    id: string;
    icon?: string;
    image?: string;
    title?: string;
    description?: string;
    time: string;
    link?: string;
    useRouter?: boolean;
    read: boolean;
    archive?: boolean;
    selected?: boolean;
    notification_type: NotificationType | null;
}

export interface NotificationResponse {
    count?: number;
    next?: string;
    previous?: string;
    results?: Notification[];
}
