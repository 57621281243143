import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Forms, Alert } from './form.types';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class FormsService {
    private _baseUrl: string = environment.apiURL;
    // Private
    private _forms: BehaviorSubject<Array<Forms>> = new BehaviorSubject(null);
    private showAlert: BehaviorSubject<Alert> = new BehaviorSubject({ alertState: false });


    /**
    * Constructor
    */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
    * Getter for Forms
    */
    get forms$(): Observable<Array<Forms>> {
        return this._forms.asObservable();
    }
    /**
    * Getter for alert
    */
    get alert$(): Observable<Alert> {
        return this.showAlert.asObservable();
    }





    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /*
    * setter for alert
    */
    setAlert(alertDetails: Alert): void {
        this.showAlert.next(alertDetails);
    }

    /**
    * Get Forms
    */
    getForms(): Observable<Array<Forms>> {
        return this._httpClient.get<Array<Forms>>(`${this._baseUrl}forms/`).pipe(
            tap((response: any) => {
                this._forms.next(response);
            }),
        );
    }
    /**
    * Get Form Details
    */
    getForm(formId: string): Observable<any> {
        return this._httpClient.get<Array<Forms>>(`${this._baseUrl}forms/${formId}/`);
    }
    /**
    * Add Form
    */
    addForms(payload: any): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}forms/`, payload).pipe(
            tap((response: any) => {
                this.getForms().subscribe();
            }),
        )
    }
    /**
    * Edit Froms
    */
    editForm(formId: string, payload: any): Observable<any> {
        return this._httpClient.patch<any>(`${this._baseUrl}forms/${formId}/`, payload).pipe(
            tap((response: any) => {
                this.getForms().subscribe();
            }),
        )
    }
    /**
    * Change Form status
    */
    changeFormStatus(formId: string, payload: any): Observable<any> {
        return this._httpClient.patch<any>(`${this._baseUrl}forms/${formId}/`, payload).pipe(
            tap((response: any) => {
                this.getForms().subscribe();
            }),
        )
    }
    /**
    * Delete Form
    */
    deleteForm(formId: string): Observable<any> {
        return this._httpClient.delete<any>(`${this._baseUrl}forms/${formId}/`).pipe(
            tap((response: any) => {
                this.getForms().subscribe();
            }),
        )
    }


    private _formDetail: BehaviorSubject<Forms> = new BehaviorSubject(null);
    get __formDetail$(): Observable<Forms> {
        return this._formDetail.asObservable();
    }
    getFormDetail(workspaceId: string, accessToken: string, formId: string): Observable<any> {
        const payload = {
            token: accessToken,
            form: formId
        }
        return this._httpClient.post<any>(`${this._baseUrl}workspace/${workspaceId}/patient/validate-token/`, payload).pipe(
            tap((response: any) => {
                this._formDetail.next(response);
            }),
        );
    }


    submitFormResp(workspaceId: string,payload:any): Observable<any> {
        return this._httpClient.post<any>(`${this._baseUrl}workspace/${workspaceId}/patient/accept-form/`, payload).pipe(
            tap((response: any) => {
                this._formDetail.next(response);
            }),
        );
    }
}
