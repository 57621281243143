export const SMS = [
    {
        title: 'Reminder message',
        message_type: 'SMS',
        frequency: 'The day before the appointment',
        content: 'Hello! Just a reminder of your scheduled X-ray procedure on 25 May at 1:00 PM.Please arrive on time with your documents.Thank you!Best regards.',
        status: 'EN'
    },
    {
        title: 'Reminder message',
        message_type: 'Email',
        frequency: 'The day before the appointment',
        content: 'Hello! Just a reminder of your scheduled X-ray procedure on 25 May at 1:00 PM.Please arrive on time with your documents.Thank you!Best regards.',
        status: 'DS'
    },
]
