
import { Injectable } from '@angular/core';
import { ExamStatusEnum } from '../exams.types';
import { SettingsService } from '../../settings/settings.service';


@Injectable({ providedIn: 'root' })
export class ActionButtonPermission {

    /**
     * Constructor
     */
    constructor(private settingsService: SettingsService) {
    }

    /**
    * Method to check save button permission based on exam status
    */
    saveButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isManager, isTranscriber, isQA, isRadiologist } = this.settingsService;

        if (isManager) {
            return false;
        }

        if (isTranscriber && exam_status === ExamStatusEnum.UNASSIGNED) {
            return false;
        }

        if (isQA && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING].includes(exam_status)) {
            return false;
        }

        if (isRadiologist && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED, ExamStatusEnum.MANAGER_REVIEW_PENDING].includes(exam_status)) {
            return false;
        }

        if (exam_status === ExamStatusEnum.FINALIZED){
            return false;
        }

        return true;
    }

    /**
      * Method to check dictation button permission based on exam status
      */
    dictationButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isManager, isTranscriber, isQA, isRadiologist } = this.settingsService;
        if (isManager) {
            return false;
        }
        if (isTranscriber && exam_status === ExamStatusEnum.UNASSIGNED) {
            return false;
        }
        if (isQA && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING].includes(exam_status)) {
            return false;
        }

        if (isRadiologist && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED, ExamStatusEnum.MANAGER_REVIEW_PENDING].includes(exam_status)) {
            return false;
        }

        return true;
    }

    /**
    * Method to check  assign button permission based on exam status
    */
    assignButtonPermission(): boolean {
        const { isManager } = this.settingsService;

        if (!isManager) {
            return false;
        }
        return true
    }

    /**
     * Method to check  status update button permission based on exam status
     */
    statusUpdateButtonPermission(): boolean {
        const { isManager } = this.settingsService;

        if (!isManager) {
            return false;
        }
        return true
    }

    /**
    * Method to check  report details button permission based on exam status
    */
    reportDetailsButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isManager, isTranscriber, isQA, isRadiologist } = this.settingsService;

        if (isManager && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED].includes(exam_status)) {
            return false;
        }
        if (isTranscriber && [ExamStatusEnum.UNASSIGNED].includes(exam_status)) {
            return false;
        }
        if (isQA && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING].includes(exam_status)) {
            return false;
        }
        if (isRadiologist && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED, ExamStatusEnum.MANAGER_REVIEW_PENDING].includes(exam_status)) {
            return false;
        }
        return true;
    }
}
