import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { CommonModule, NgFor, NgStyle, UpperCasePipe } from '@angular/common';
import { MatCheckbox } from '@angular/material/checkbox';
import { FuseAlertService } from '@fuse/components/alert';
import { AccountDetails, Workspace } from 'app/modules/admin/settings/settings.types';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { SettingsService } from 'app/modules/admin/settings/settings.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-choose-workspace-dialog',
  templateUrl: './choose-workspace-dialog.component.html',
  styleUrls: ['./choose-workspace-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    UpperCasePipe,
    MatIcon,
    NgStyle,
    MatButtonModule,
    MatTabsModule,
    MatCheckbox,
    CommonModule
  ],
})
export class ChooseWorkspaceDialogComponent implements OnInit, OnDestroy {
  selectedWorkspace: Workspace = null;
  pendingWorkspaceCount: number;
  userData: AccountDetails;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    public dialog: MatDialog,
    private _alertService: FuseAlertService,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _settingsService: SettingsService,
  ) { }

  ngOnInit(): void {
    this._settingsService.accountDetails$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((accountDetails: AccountDetails) => {
        this.userData = accountDetails;
        this.pendingWorkspaceCount = this.userData.workspaces.filter((w) => w.status === 'PD').length;
        this._changeDetectorRef.markForCheck();
      })
  }

  onSelectionChange(item: Workspace) {
    if (item.workspace_id === this.selectedWorkspace?.workspace_id) {
      this.selectedWorkspace = null;
    } else {
      this.selectedWorkspace = item;
    }
  }

  accept(item: Workspace) {
    this._settingsService.acceptWorkspaceInvite(item.workspace_id).pipe(
      tap(() => {
        this._alertService.setAlert({
          alertState: true, alertData: {
            type: 'success',
            message: `The ${item.workspace} workspace has been added successfully! Well done`,
            actionButtonConfig: { show: false, buttonText: null },
            class: 'w-160'
          }
        });
      }), catchError((error) => {
        this._alertService.setAlert({
          alertState: true, alertData: {
            type: 'error',
            message: 'Oops, something went wrong. Unfortunately, the changes were not saved. Please try again.',
            actionButtonConfig: { show: false, buttonText: null },
            class: 'w-auto'
          }
        });
        console.error(error);
        return of(null);
      })
    ).subscribe();
  }

  decline(item: Workspace) {
    this._settingsService.declineWorkspaceInvite(item.workspace_id).pipe(
      tap(() => {
        this._alertService.setAlert({
          alertState: true, alertData: {
            type: 'success',
            message: `The ${item.workspace} workspace has been declined successfully!`,
            actionButtonConfig: { show: false, buttonText: null },
            class: 'w-160'
          }
        });
      }), catchError((error) => {
        this._alertService.setAlert({
          alertState: true, alertData: {
            type: 'error',
            message: 'Oops, something went wrong. Unfortunately, the changes were not saved. Please try again.',
            actionButtonConfig: { show: false, buttonText: null },
            class: 'w-auto'
          }
        });
        console.error(error);
        return of(null);
      })
    ).subscribe();
  }

  open() {
    if (this.selectedWorkspace.workspace_id !== this.userData.workspace) {
      const payload = {
        workspace_name: this.selectedWorkspace.workspace,
        workspace_id: this.selectedWorkspace.workspace_id
      }
      this._authService.switchWorkspace(payload).pipe(
        tap((_) => {
        }), catchError((error) => {
          this._alertService.setAlert({
            alertState: true, alertData: {
              type: 'error',
              message: 'Oops, something went wrong. Unfortunately, the changes were not saved. Please try again.',
              actionButtonConfig: { show: false, buttonText: null },
              class: 'w-auto'
            }
          });
          console.error(error);
          return of(null);
        })
      ).subscribe();
    } else {
      this._alertService.setAlert({
        alertState: true, alertData: {
          type: 'error',
          message: `You're already viewing the ${this.selectedWorkspace.workspace} workspace.`,
          actionButtonConfig: { show: false, buttonText: null },
          class: 'w-auto'
        }
      });
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
