import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { Pacs, Alert, WorkList } from './pacs.types'
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class PacsService {
    private _baseUrl: string = environment.apiURL;
    // Private
    private _pacs: BehaviorSubject<Array<Pacs>> = new BehaviorSubject(null);
    private showAlert: BehaviorSubject<Alert> = new BehaviorSubject({ alertState: false });


    /**
    * Constructor
    */
    constructor(private _httpClient: HttpClient) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
    * Getter for Pacs
    */
    get pacs$(): Observable<Array<Pacs>> {
        return this._pacs.asObservable();
    }
    /**
    * Getter for alert
    */
    get alert$(): Observable<Alert> {
        return this.showAlert.asObservable();
    }





    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /*
    * setter for alert
    */
    setAlert(alertDetails: Alert): void {
        this.showAlert.next(alertDetails);
    }

    /**
    * Get Pacs
    */
    getPacs(page: number = 1, filter?: any): Observable<Array<Pacs>> {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', 25);
        for (let key in filter) {
            params = params.append(key, filter[key]);
        }
        return this._httpClient.get<Array<Pacs>>(`${this._baseUrl}studies/`, { params }).pipe(
            tap((response: any) => {
                this._pacs.next(response.results);
            }),
        );
    }

    uploadStudies(payload: any) {
        return this._httpClient.post<any>(`${this._baseUrl}studies/bulk-upload/`, payload, {
            reportProgress: true,
            observe: 'events'
        });
    }

    attachReport(studyId: string, payload: any) {
        return this._httpClient.post<any>(`${this._baseUrl}studies/${studyId}/report/`, payload).pipe(
            tap((response: any) => {
                this.getPacs().subscribe();
            }),
        );
    }

    shareReport(payload: any) {
        return this._httpClient.post<any>(`${this._baseUrl}studies/share/`, payload);
    }


    private _study: BehaviorSubject<Pacs> = new BehaviorSubject(null);
    get study$(): Observable<Pacs> {
        return this._study.asObservable();
    }
    getStudy(studyId: string): Observable<Pacs> {
        return this._httpClient.get<Pacs>(`${this._baseUrl}studies/${studyId}/`).pipe(
            tap((response: any) => {
                this._study.next(response);
            }),
        );
    }

    private _sharedStudy: BehaviorSubject<Array<Pacs>> = new BehaviorSubject(null);
    get shareStudy$(): Observable<Array<Pacs>> {
        return this._sharedStudy.asObservable();
    }
    getShareStudy(shareToken: string, workspaceId?: string): Observable<Array<Pacs>> {
        let routeParam = `${shareToken}`;
        if (workspaceId) {
            routeParam = `${workspaceId}/${shareToken}`;
        }
        return this._httpClient.get<Array<Pacs>>(`${this._baseUrl}studies/share/${routeParam}/`).pipe(
            tap((response: any) => {
                this._sharedStudy.next(response);
            }),
        );
    }


    private _workList: BehaviorSubject<Array<WorkList>> = new BehaviorSubject(null);
    get workList$(): Observable<Array<WorkList>> {
        return this._workList.asObservable();
    }
    getWorkList(page: number = 1, filter?: any): Observable<Array<WorkList>> {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('size', 25);
        for (let key in filter) {
            params = params.append(key, filter[key]);
        }
        return this._httpClient.get<Array<WorkList>>(`${this._baseUrl}get_worklists/`, { params }).pipe(
            tap((response: any) => {
                this._workList.next(response.results);
            }),
        );
    }

    documentFileOrViewerDownload(studyId: string, type: string): Observable<{download_url:string}> {
        let params = new HttpParams();
        params = params.append('study_id', studyId);
        params = params.append('type', type);
        return this._httpClient.get<any>(`${this._baseUrl}studies/download-images/`, { params }).pipe(
            tap((response: any) => {
                // this._workList.next(response.results);
                console.log('service resp',response)
            })
        );
    }

    deleteWorklist(id: string): Observable<any> {
        return this._httpClient.delete(`${this._baseUrl}worklists/${id}/`);
    }
}
