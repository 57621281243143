import { ActivatedRouteSnapshot, Route } from "@angular/router";
import { initialDataResolver } from "app/app.resolvers";
import { AuthGuard } from "app/core/auth/guards/auth.guard";
import { NoAuthGuard } from "app/core/auth/guards/noAuth.guard";
import { LayoutComponent } from "app/layout/layout.component";
import { AuthService } from "app/core/auth/auth.service";
import { inject } from "@angular/core";
import { FormsService } from "./modules/admin/settings/form-management/form.service";
import { ShareStudyDetailsComponent } from "./modules/admin/pacs/share-study-details/share-study-details.component";
import { PacsService } from "./modules/admin/pacs/pacs.service";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  // Redirect empty path to '/example'
  { path: "", pathMatch: "full", redirectTo: "appointments" },

  // Redirect signed-in user to the '/example'
  //
  // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
  // path. Below is another redirection for that path to redirect the user to the desired
  // location. This is a small convenience to keep all main routes together here on this file.
  { path: "signed-in-redirect", pathMatch: "full", redirectTo: "appointments" },

  // Auth routes for guests
  {
    path: "",
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "confirmation-required",
        loadChildren: () =>
          import(
            "app/modules/auth/confirmation-required/confirmation-required.routes"
          ),
      },
      {
        path: "forgot-password",
        loadChildren: () =>
          import("app/modules/auth/forgot-password/forgot-password.routes"),
      },
      {
        path: "workspace/:workspaceId/reset-password/:uid/:token",
        loadChildren: () =>
          import("app/modules/auth/reset-password/reset-password.routes"),
      },
      {
        path: "sign-in",
        loadChildren: () => import("app/modules/auth/sign-in/sign-in.routes"),
      },
      {
        path: "workspace/:workspaceId/accept-invite/:inviteToken",
        loadChildren: () => import("app/modules/auth/sign-up/sign-up.routes"),
        resolve: {
          dicomRouter: (route: ActivatedRouteSnapshot) =>
            inject(AuthService).getInvitationDetail(
              route.params["workspaceId"],
              route.params["inviteToken"],
            ),
        },
      },
    ],
  },

  // Auth routes for authenticated users
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "sign-out",
        loadChildren: () => import("app/modules/auth/sign-out/sign-out.routes"),
      },
      {
        path: "unlock-session",
        loadChildren: () =>
          import("app/modules/auth/unlock-session/unlock-session.routes"),
      },
    ],
  },

  // Landing routes
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: "home",
        loadChildren: () => import("app/modules/landing/home/home.routes"),
      },
    ],
  },

  // Form Rendered
  {
    path: "",
    component: LayoutComponent,
    data: {
      layout: "empty",
    },
    children: [
      {
        path: 'workspace/:workspaceId/form/:form_id/:accessToken',
        loadChildren: () => import('app/modules/landing/form/form.route'),
        resolve: {
          formDetails: (route: ActivatedRouteSnapshot) => inject(FormsService).getFormDetail(route.params['workspaceId'], route.params['accessToken'], route.params['form_id']),
        }
      },
      {
        path: "study",
        loadChildren: () =>
          import("app/modules/landing/access-shared-study/accessStudy.route"),
      },
      {
        path: ':workspaceId/pacs/share/:shareToken',
        component: ShareStudyDetailsComponent,
        pathMatch: 'full',
        resolve: {
          studyDetails: (route: ActivatedRouteSnapshot) => inject(PacsService).getShareStudy(
            route.params['shareToken'],
            route.params['workspaceId']
          ),
        },
      },
    ],
  },

  // Admin routes
  {
    path: "",
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: "example",
        loadChildren: () => import("app/modules/admin/example/example.routes"),
      },
      {
        path: "invoices",
        loadChildren: () =>
          import("app/modules/admin/invoices/invoices.routes"),
      },
      {
        path: "workspaces",
        loadChildren: () =>
          import("app/modules/admin/workspaces/workspaces.routes"),
      },
      {
        path: "patients",
        loadChildren: () =>
          import("app/modules/admin/patients/patients.routes"),
      },
      {
        path: "appointments",
        loadChildren: () =>
          import("app/modules/admin/appointments/appointments.routes"),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("app/modules/admin/settings/settings.routes"),
      },
      {
        path: "exams",
        loadChildren: () => import("app/modules/admin/exams/exams.routes"),
      },
      {
        path: "pacs",
        loadChildren: () => import("app/modules/admin/pacs/pacs.routes"),
      },
      {
        path: "activity",
        loadChildren: () =>
          import("app/modules/admin/activity-logs/activity.routes"),
      },
      {
        path: "account",
        loadChildren: () => import("app/modules/admin/account/account.routes"),
      },
      {
        path: "dicom-viewer",
        loadComponent: () =>
          import("./modules/dicom-viewer/dicom-viewer.component").then(
            (c) => c.DicomViewerComponent,
          ),
      },
    ],
  },
];
