<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation.default" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-[136px] h-[34px]" src="assets/images/logo/logo.svg" alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    @if(headerState?.flag == 'default'){
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b dark:bg-transparent print:hidden bg-darkprimary-300 border-solid border-b border-darkprimary-50">
        <ng-container *ngIf="!isScreenSmall">
            <div class="flex min-w-[136px] h-[34px] mr-lg-12">
                <img class="w-full h-full object-cover" src="assets/images/logo/logo-white.svg">
            </div>
            <fuse-horizontal-navigation class="font-host font-normal text-base leading-5 text-textprimary-100 navlist"
                [name]="'mainNavigation'" [navigation]="navigation.horizontal"></fuse-horizontal-navigation>
        </ng-container>
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
            </button>
        </ng-container>
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <div class="mr-7">
                <my-workspaces></my-workspaces>
            </div>
            <notifications></notifications>
            <mat-divider class="h-full w-[1px] min-h-6 bg-darkprimary-50 border-darkprimary-50 text-darkprimary-50"
                [vertical]="true"></mat-divider>
            <user></user>
        </div>
    </div>
    }


    @if(headerState?.flag != 'default'){
    <div
        class="relative flex flex-0 items-center w-full h-16 sm:h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b dark:bg-transparent print:hidden bg-darkprimary-300 border-solid border-b border-darkprimary-50">
        <ng-container>
            <div class="flex w-8 h-9 mr-2">
                <img class="w-full h-full object-cover" src="assets/images/logo/logo.svg">
            </div>
        </ng-container>
        <ng-container *ngIf="!isScreenSmall">
            @if(headerState?.flag == 'invoiceDetails'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Subscription</p>
                </div>
                <div>
                    <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Invoice</p>
                    <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.invoiceId}}</p>
                </div>
            </div>
            }
            @if(headerState?.flag === 'appointmentDetails'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Appointments</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Appointment No</p>
                        <p class="font-host font-normal text-base leading-5 text-white">
                            {{headerState.data.appointmentId}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Name</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.patientName}}
                        </p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">MRN ID</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.mrn}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Gender</p>
                        <p class="font-host font-normal text-base leading-5 text-white">
                            {{headerState.data.gender.toLowerCase() === 'male' ? 'M' : 'F'}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Age</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.age}} yrs</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Modality</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.modality}}
                        </p>
                    </div>
                </div>
            </div>
            }
            @if(headerState?.flag == 'examDetails'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Exams</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <div class="px-2.5 py-0.5 rounded border  font-host font-normal text-sm leading-4 "
                            [ngClass]="headerState.data?.status?.toLowerCase() === 'critical' ? 'border-red-500 text-red-500' : 'text-gray-500 border-gray-500'">
                            {{headerState.data?.status}}</div>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Name</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.name}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Status</p>
                        <div
                            class="px-2.5 py-0.5 rounded border  font-host font-normal text-sm leading-4 border-[#20AFFF] text-[#20AFFF]">
                            {{headerState.data?.examStatus == 'MI' ? 'Missing Image' :
                            headerState.data?.examStatus?.replaceAll('_', ' ')?.toLowerCase() | titlecase}}</div>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">MRN ID</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.mrn}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">EXAM DATE</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState?.data?.examDate |
                            date :'MM/dd/yyyy'}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Gender</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.gender |
                            titlecase}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Age</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.age}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Modality</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.modality}}
                        </p>
                    </div>
                    <div class="px-3" *ngIf="headerState.data.referel">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Referrer</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.referel}}</p>
                    </div>
                </div>

            </div>
            }

            @if(headerState?.flag === 'patientDetails'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Patients</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Name</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.full_name}}
                        </p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">ID</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.id}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Gender</p>
                        <p class="font-host font-normal text-base leading-5 text-white">
                            {{headerState.data.gender.toLowerCase() ===
                            'male' ? 'M' : 'F'}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Age</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.age}} yrs</p>
                    </div>
                </div>
            </div>
            }

            @if(headerState?.flag === 'newPatient'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Patients</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Patient</p>
                        <p class="font-host font-normal text-base leading-5 text-white">New Patient</p>
                    </div>
                </div>
            </div>
            }

            @if(headerState?.flag === 'newAppointment'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Appointments</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Appointment</p>
                        <p class="font-host font-normal text-base leading-5 text-white">Create New Appoinment</p>
                    </div>
                </div>
            </div>
            }

            @if(headerState?.flag === 'viewForm'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Exams</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Intake Form</p>
                        <p class="font-host font-normal text-base leading-5 text-white">Form Details</p>
                    </div>
                </div>
            </div>
            }

            @if(headerState?.flag == 'teamDetails'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Team</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Name</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.memberName}}
                        </p>
                    </div>
                </div>
            </div>
            }
            @if(headerState?.flag === 'studyDetails'){
            <div class="flex items-center">
                <div class="flex items-center border-r border-solid border-darkprimary-50 pr-2 mr-8">
                    <button mat-button (click)="headerState.backFuncRef()"
                        class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80">
                        <mat-icon svgIcon="heroicons_mini:chevron-left" class="size-6 text-white"></mat-icon>
                    </button>
                    <p class="font-host font-bold text-base leading-5 text-white ml-2">Studies</p>
                </div>
                <div class="-mx-3 flex items-center flex-wrap">
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Study No</p>
                        <p class="font-host font-normal text-base leading-5 text-white">
                            {{headerState.data.id}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Patient Name</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.patientName}}
                        </p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">MRN ID</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.mrn}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Gender</p>
                        <p class="font-host font-normal text-base leading-5 text-white">
                            {{headerState.data.gender}}</p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Modality</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.modality}}
                        </p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Exam</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.exam}}
                        </p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">DOS</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.dos}}
                        </p>
                    </div>
                    <div class="px-3">
                        <p class="text-textprimary-100 font-host font-normal text-sm leading-5">Time</p>
                        <p class="font-host font-normal text-base leading-5 text-white">{{headerState.data.time}}
                        </p>
                    </div>
                </div>
            </div>
            }
        </ng-container>


        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <div class="flex items-center">
                @if(headerState?.flag == 'examDetails'){
                @if(headerState.data.showFullScreen){
                <button mat-button (click)="toggleFullScreen()"
                    class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80 mr-2">
                    <mat-icon svgIcon="feather:minimize" class="size-5 min-w-min min-h-min text-white"></mat-icon>
                </button>
                }
                @if(headerState.data.showMicrophone &&
                actionButtonPermission.dictationButtonPermission(headerState.data?.examStatus)) {
                @if(micMute){
                <button mat-button (click)="micToggle();headerState.startRecRef()"
                    class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80 mr-2">
                    <mat-icon svgIcon="feather:mic" class="size-5 min-w-min min-h-min text-white"></mat-icon>
                </button>
                }@else {
                <button mat-button (click)="micToggle();headerState.stopRecRef()"
                    class="w-6 h-6 min-w-max min-h-max p-0 flex items-center justify-center bg-transparent outline-none border-none hover:opacity-80 mr-2">
                    <mat-icon svgIcon="feather:mic-off" class="size-5 min-w-min min-h-min text-white"></mat-icon>
                </button>
                }

                }
                <div class="border-l border-[#333844] h-6"></div>
                }
                <notifications></notifications>
                <div class="border-l border-[#333844] h-6"></div>
                <user></user>
            </div>
        </div>
    </div>
    }


    <!-- Content -->
    <div class="flex flex-col flex-auto w-full">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">Fuse &copy; {{currentYear}}</span>
    </div> -->

</div>

<!-- Quick chat -->
<!-- <quick-chat #quickChat="quickChat"></quick-chat> -->