import { Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api/mock-api.service';
import { CompanyProfile,PersonalInformation } from './data';
import { cloneDeep } from 'lodash-es';

@Injectable({ providedIn: 'root' })
export class AccountMockApi {
    private _companyProfile:any = CompanyProfile;
    private _personalInformation:any = PersonalInformation;

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        this._fuseMockApiService
            .onGet('api/apps/companyprofile')
            .reply(() => {
                // Clone the Exam
                const companyProfile = cloneDeep(this._companyProfile);
                return [200, companyProfile];
            });

            this._fuseMockApiService
            .onGet('api/apps/personalInformation')
            .reply(() => {
                // Clone the Exam
                const personalInformation = cloneDeep(this._personalInformation);
                return [200, personalInformation];
            });
    }
}
