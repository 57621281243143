export const CompanyProfile = {
    company: 'Life Imgaing Fla, Deer Field Office',
    email: 'support@lifeimgaingfla.com',
    phone: '+1-212-456-7890',
    about: 'Switch your subscription to a different type, such as a monthly plan, annual plan, or student plan.Switch your subscription to a different type, such as a monthly plan, annual plan, or student plan. Switch your subscription to a different type, such as a monthly plan, annual plan, or student plan.  ',
}

export const PersonalInformation = {
    fullName:'Christopher Henderson',
    email:'christopher_henderson@example.com',
    phone:'+1-212-456-7890',
    role:'admin',
}
