export const examsList = [
    {
        id: '123456789EXAM1',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                            

                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            <strong>CT CHEST WITHOUT CONTRAST:</strong>
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                           <strong>TECHNIQUE:</strong>
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            <strong>FINDINGS:</strong>
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM22',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM111',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },

            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM1111',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [{
                report_id: '1234567890R1',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },
            {
                report_id: '1234567890R2',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            }],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM155',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [{
                report_id: '1234567890R1',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },
            {
                report_id: '1234567890R2',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            }],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM166',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [{
                report_id: '1234567890R1',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },
            {
                report_id: '1234567890R2',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM189',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [{
                report_id: '1234567890R1',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },
            {
                report_id: '1234567890R2',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM177',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [{
                report_id: '1234567890R1',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },
            {
                report_id: '1234567890R2',
                modality_id: '1234567890M1',
                reportHtml: `  <div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-6"
                    >
                        CT CHEST WITHOUT CONTRAST:
                    </p>
                </div>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-1"
                    >
                        TECHNIQUE:
                    </p>
                </div>

                <p
                    class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                >
                    Thin section axial images were obtained through the
                    chest using a GE Lightspeed scanner. No intravenous
                    contrast was used.
                </p>

                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                        >10</span
                    >
                    <p
                        class="text-white text-base font-bold font-host mb-0"
                    >
                        FINDINGS:
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >8</span
                    >
                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6"
                    >
                        <strong
                            class="text-white text-base font-bold font-host"
                            >Lung nodules:</strong
                        >
                        A small calcified micronodule is noted at the
                        posterior segment of the right upper lobe.<br />
                        <span
                            class="text-textprimary-300 text-base font-normal font-host"
                        >
                            A triangular nodule is noted at the lateral
                            segment of the left lower lobe most likely
                            related to a lymph node.
                        </span>
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >8</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong
                            class="text-white font-bold font-host"
                            >Consolidations:
                        </strong>
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Interstitial disease or emphysema:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Other parenchymal disease:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-host font-normal"
                    >
                        <strong class="text-white font-bold font-host"
                            >Trachea and central bronchi:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Pleural effusion or pneumothorax:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host">
                            Lymph nodes:</strong
                        >
                        A few lymph nodes are noted at the prevascular,
                        paratracheal and subcarinal area related to normal
                        lymphatic tissue or benign reactive changes.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Aortic aneurysm:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Calcific atherosclerosis:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Cardiomegaly:</strong
                        >
                        None
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Valvular calcifications:</strong
                        >
                        None
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Chest wall:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Esophagus:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >3</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thyroid:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >6</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Thoracic:</strong
                        >
                        Mild multilevel anterior osteophytes are noted.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Bones:</strong
                        >
                        Unremarkable.
                    </p>
                </div>

                <div class="flex pl-1 mb-4">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >2</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host mb-3"
                    >
                        <strong
                            class="text-white font-bold font-host mb-6"
                            >Other Findings:</strong
                        >
                        None.
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block mb-6"
                        >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >7</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >TECHNIQUE:
                    </span>
                </div>

                <p
                    class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                >
                    Thin section axial images were obtained through the
                    abdomen and pelvis using a GE Lightspeed scanner. No
                    intravenous contrast was used.FINDINGS:
                </p>
                <div class="flex">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >10</span
                    >
                    <span
                        class="text-base text-white font-bold font-host block"
                        >FINDINGS:</span
                    >
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Liver:</strong
                        >
                        Unremarkable
                    </p>
                </div>
                <div class="flex pl-1">
                    <span
                        class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                        >5</span
                    >
                    <p
                        class="text-base text-textprimary-300 font-normal font-host"
                    >
                        <strong class="text-white font-bold font-host"
                            >Spleen:</strong
                        >
                        Unremarkable.
                    </p>
                </div>
            </div>`,
                exam_date: '10-21-2024',
                pdf_url: 'www.cacius.com',
            },],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM1p',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                // {
                //     report_id: '1234567890R3',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R4',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R5',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R6',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R7',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM199',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                // {
                //     report_id: '1234567890R3',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R4',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R5',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R6',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
                // {
                //     report_id: '1234567890R7',
                //     modality_id: '1234567890M1',
                //     reportHtml:`<div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-6"
                //             >
                //                 CT CHEST WITHOUT CONTRAST:
                //             </p>
                //         </div>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-1"
                //             >
                //                 TECHNIQUE:
                //             </p>
                //         </div>

                //         <p
                //             class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                //         >
                //             Thin section axial images were obtained through the
                //             chest using a GE Lightspeed scanner. No intravenous
                //             contrast was used.
                //         </p>

                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2 leading-0"
                //                 >10</span
                //             >
                //             <p
                //                 class="text-white text-base font-normal font-host mb-0"
                //             >
                //                 FINDINGS:
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-textprimary-300 text-base font-normal font-host mb-6"
                //             >
                //                 <strong
                //                     class="text-white text-base font-normal font-host"
                //                     >Lung nodules:</strong
                //                 >
                //                 A small calcified micronodule is noted at the
                //                 posterior segment of the right upper lobe.<br />
                //                 <span
                //                     class="text-textprimary-300 text-base font-normal font-host"
                //                 >
                //                     A triangular nodule is noted at the lateral
                //                     segment of the left lower lobe most likely
                //                     related to a lymph node.
                //                 </span>
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >8</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-normal font-host"
                //                     >Consolidations:
                //                 </strong>
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal pr-2 font-host"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Interstitial disease or emphysema:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Other parenchymal disease:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-host font-normal"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Trachea and central bronchi:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Pleural effusion or pneumothorax:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host">
                //                     Lymph nodes:</strong
                //                 >
                //                 A few lymph nodes are noted at the prevascular,
                //                 paratracheal and subcarinal area related to normal
                //                 lymphatic tissue or benign reactive changes.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Aortic aneurysm:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-host font-normal pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Calcific atherosclerosis:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Cardiomegaly:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Valvular calcifications:</strong
                //                 >
                //                 None
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Chest wall:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Esophagus:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >3</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thyroid:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >6</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Thoracic:</strong
                //                 >
                //                 Mild multilevel anterior osteophytes are noted.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Bones:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>

                //         <div class="flex pl-1 mb-4">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >2</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host mb-3"
                //             >
                //                 <strong
                //                     class="text-white font-normal font-host mb-6"
                //                     >Other Findings:</strong
                //                 >
                //                 None.
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block mb-6"
                //                 >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >7</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >TECHNIQUE:
                //             </span>
                //         </div>

                //         <p
                //             class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                //         >
                //             Thin section axial images were obtained through the
                //             abdomen and pelvis using a GE Lightspeed scanner. No
                //             intravenous contrast was used.FINDINGS:
                //         </p>
                //         <div class="flex">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >10</span
                //             >
                //             <span
                //                 class="text-base text-white font-normal font-host block"
                //                 >FINDINGS:</span
                //             >
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Liver:</strong
                //                 >
                //                 Unremarkable
                //             </p>
                //         </div>
                //         <div class="flex pl-1">
                //             <span
                //                 class="inline-block text-textprimary-100 text-base font-normal font-host pr-2"
                //                 >5</span
                //             >
                //             <p
                //                 class="text-base text-textprimary-300 font-normal font-host"
                //             >
                //                 <strong class="text-white font-normal font-host"
                //                     >Spleen:</strong
                //                 >
                //                 Unremarkable.
                //             </p>
                //         </div>
                //     </div>`,
                //     exam_date: '10-21-2024',
                //     pdf_url: 'www.cacius.com',
                // },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM2',
        status: 'Critical',
        due: '2024-08-19T00:00:00.000Z',
        dos: '2024-07-28T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM3',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM4',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM5',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM6',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM7',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM8',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM9',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM105',
        status: 'Critical',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
    {
        id: '123456789EXAM11555',
        status: 'Stroke',
        due: '2024-08-01T00:00:00.000Z',
        dos: '2024-07-30T00:00:00.000Z',
        patient: {
            patient_id: '1234567890P1',
            first_name: 'Peter',
            last_name: 'Parker',
            sex: 'M',
            birth_date: '10-21-1998',
            scan_id: '1234567890S1',
            email: 'christopher_henderson@example.com',
            phone: '(234) 567-8901',
            address: '123 Main Street, Anytown, USA',
            age: 99,
            emergency_contact: {
                first_name: 'May',
                last_name: 'Parker',
                relation: 'aunt',
                phone: '(234) 567-8901',
            },
            insurance_information: {
                policy_number: 'XYZ123456789',
                authorization_number: 'ABC987654321',
                provider: 'Kaiser Permanente',
            },
            reports: [
                {
                    report_id: '1234567890R1',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R2',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,

                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R3',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R4',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R5',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R6',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
                {
                    report_id: '1234567890R7',
                    modality_id: '1234567890M1',
                    reportHtml: `  <div>
                    <div class="flex">
                       
                        <p
                            class="text-white text-base font-bold font-host mb-6"
                        >
                            CT CHEST WITHOUT CONTRAST:
                        </p>
                    </div>
                    <div class="flex">
                        
                        <p
                            class="text-white text-base font-bold font-host mb-1"
                        >
                            TECHNIQUE:
                        </p>
                    </div>

                    <p
                        class="text-textprimary-300 text-base font-normal font-host mb-6 pl-4"
                    >
                        Thin section axial images were obtained through the
                        chest using a GE Lightspeed scanner. No intravenous
                        contrast was used.
                    </p>

                    <div class="flex">
                        <span
                        
                        <p
                            class="text-white text-base font-bold font-host mb-0"
                        >
                            FINDINGS:
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-textprimary-300 text-base font-normal font-host mb-6"
                        >
                            <strong
                                class="text-white text-base font-bold font-host"
                                >Lung nodules:</strong
                            >
                            A small calcified micronodule is noted at the
                            posterior segment of the right upper lobe.<br />
                            <span
                                class="text-textprimary-300 text-base font-normal font-host"
                            >
                                A triangular nodule is noted at the lateral
                                segment of the left lower lobe most likely
                                related to a lymph node.
                            </span>
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong
                                class="text-white font-bold font-host"
                                >Consolidations:
                            </strong>
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Interstitial disease or emphysema:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Other parenchymal disease:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-host font-normal"
                        >
                            <strong class="text-white font-bold font-host"
                                >Trachea and central bronchi:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Pleural effusion or pneumothorax:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host">
                                Lymph nodes:</strong
                            >
                            A few lymph nodes are noted at the prevascular,
                            paratracheal and subcarinal area related to normal
                            lymphatic tissue or benign reactive changes.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Aortic aneurysm:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Calcific atherosclerosis:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Cardiomegaly:</strong
                            >
                            None
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Valvular calcifications:</strong
                            >
                            None
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Chest wall:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Esophagus:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thyroid:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Thoracic:</strong
                            >
                            Mild multilevel anterior osteophytes are noted.
                        </p>
                    </div>
                    <div class="flex pl-1">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Bones:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>

                    <div class="flex pl-1 mb-4">
                        
                        <p
                            class="text-base text-textprimary-300 font-normal font-host mb-3"
                        >
                            <strong
                                class="text-white font-bold font-host mb-6"
                                >Other Findings:</strong
                            >
                            None.
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <span
                            class="text-base text-white font-bold font-host block mb-6"
                            >CT ABDOMEN AND PELVIS WITHOUT CONTRAST:</span
                        >
                    </div>
                    <div class="flex pl-1">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >TECHNIQUE:
                        </span>
                    </div>

                    <p
                        class="text-base text-textprimary-300 font-normal font-host pl-4 mb-4"
                    >
                        Thin section axial images were obtained through the
                        abdomen and pelvis using a GE Lightspeed scanner. No
                        intravenous contrast was used.FINDINGS:
                    </p>
                    <div class="flex">
                        
                        <span
                            class="text-base text-white font-bold font-host block"
                            >FINDINGS:</span
                        >
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Liver:</strong
                            >
                            Unremarkable
                        </p>
                    </div>
                    <div class="flex pl-1">
                       
                        <p
                            class="text-base text-textprimary-300 font-normal font-host"
                        >
                            <strong class="text-white font-bold font-host"
                                >Spleen:</strong
                            >
                            Unremarkable.
                        </p>
                    </div>
                </div>`,
                    exam_date: '10-21-2024',
                    pdf_url: 'www.cacius.com',
                },
            ],
            images: [
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
                {
                    image_id: '1234567890I1',
                    url: 'www.cacius.com',
                },
            ],
        },
        mrn: '0018247365',
        modality: 'MRI',
        exam: 'Dental radiography (dental X-rays)',
        role: 'FR',
        assignee: {
            first_name: 'Tom',
            last_name: 'Holland',
        },
        referrer: {
            first_name: 'Andrew',
            last_name: 'Garfield',
        },
        facility: 'Kaiser Permanente',
        accession: '1234567890',
        exam_status: 'Scheduled',
        submit_date: '09-18-2024',
        submit_time: '01:15 PM',
        examDetails: `<div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PATIENT INFO:</p>

                    <p class="text-base font-bold text-white">Name: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Christopher Henderson</span>
                    </p>
                    <p class="text-base font-bold text-white">ID: <span
                            class="text-base text-textprimary-300 font-normal inline-block">6247866</span></p>
                    <p class="text-base font-bold text-white">Sex: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Male</span></p>
                    <p class="text-base font-bold text-white">Ege: <span
                            class="text-base text-textprimary-300 font-normal inline-block">77</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">REFERRAL INFO:</p>

                    <p class="text-base font-bold text-white">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Dr. Cameron</span></p>
                    <p class="text-base font-bold text-white">Contact: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Camerondrmmail.com</span>
                    </p>
                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">EXAM INFO:</p>

                    <p class="text-base font-bold text-white">Data: <span
                            class="text-base text-textprimary-300 font-normal inline-block">April 25, 2024</span></p>
                    <p class="text-base font-bold text-white">Type of examination: <span
                            class="text-base text-textprimary-300 font-normal inline-block">MRT</span></p>
                    <p class="text-base font-bold text-white">Procedure Duration: <span
                            class="text-base text-textprimary-300 font-normal inline-block">45 min</span></p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>

                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">STATUS:<span
                            class="text-darkprimary-600 font-normal border border-solid border-darkprimary-600 rounded py-1 px-3 capitalize ml-2">Verifying</span>
                    </p>

                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">PERSONNEL:</p>

                    <p class="text-base font-bold text-white">Radiologist: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Oliver Johnson</span></p>
                    <p class="text-base font-bold text-white">Transcriber: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Benjamin Evans</span></p>
                    <p class="text-base font-bold text-white">QA: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Charlotte Williams</span>
                    </p>
                    <p class="text-base font-bold text-white">Equipment Used: <span
                            class="text-base text-textprimary-300 font-normal inline-block">Siemens MAGNETOM Skyra 3T
                            MRI Scanner</span></p>



                </div>
                <div class="mb-8">
                    <p class="
                text-base font-bold  text-white uppercase">NOTES:</p>

                    <p class="text-base font-bold text-white mb-2">Doctor: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient feels slightly
                            weak and has a mild cough. Rest and consumption of warm beverages are recommended.</span>
                    </p>
                    <p class="text-base font-bold text-white">Technicians: <span
                            class="text-base text-textprimary-300 font-normal inline-block">The patient complains of
                            back pain, which worsens with movement. A spine X-ray is recommended.</span></p>
                </div>`
    },
];

export const modalities = [
    { modality_name: 'MRI', modality_id: 'MOD1', selected: false },
    { modality_name: 'CT', modality_id: 'MOD2', selected: false },
    { modality_name: 'Ultrasound', modality_id: 'MOD3', selected: false },
    { modality_name: 'PET/CT', modality_id: 'MOD4', selected: false },
    { modality_name: 'X-RAY', modality_id: 'MOD5', selected: false },
];

export const doctors = [
    {
        doctor_id: 'DOC-01',
        doctor_name: 'JOHN PARKER',
        selected: false
    },
    {
        doctor_id: 'DOC-02',
        doctor_name: 'JOHN SEN',
        selected: false
    },
    {
        doctor_id: 'DOC-03',
        doctor_name: 'JOHN PUTIN',
        selected: false
    },
    {
        doctor_id: 'DOC-04',
        doctor_name: 'Tom Holland',
        selected: false
    }



]

export const radiologist = [
    {
        doctor_id: 'RAD-01',
        doctor_name: 'JOHN PARKER',
        selected: false
    },
    {
        doctor_id: 'RAD-02',
        doctor_name: 'JOHN SEN',
        selected: false
    },
    {
        doctor_id: 'RAD-03',
        doctor_name: 'JOHN PUTIN',
        selected: false
    },
    {
        doctor_id: 'RAD-04',
        doctor_name: 'Tom Holland',
        selected: false
    }
];

export const qa = [
    {
        doctor_id: 'QA-01',
        doctor_name: 'JOHN PARKER',
        selected: false
    },
    {
        doctor_id: 'QA-02',
        doctor_name: 'JOHN SEN',
        selected: false
    },
    {
        doctor_id: 'QA-03',
        doctor_name: 'JOHN PUTIN',
        selected: false
    },
    {
        doctor_id: 'QA-04',
        doctor_name: 'Tom Holland',
        selected: false
    }
];

export const transcriber = [
    {
        doctor_id: 'TRN-01',
        doctor_name: 'JOHN PARKER',
        selected: false
    },
    {
        doctor_id: 'TRN-02',
        doctor_name: 'JOHN SEN',
        selected: false
    },
    {
        doctor_id: 'TRN-03',
        doctor_name: 'JOHN PUTIN',
        selected: false
    },
    {
        doctor_id: 'TRN-04',
        doctor_name: 'Tom Holland',
        selected: false
    }
];