export const dicomRouters = [
    {
        routing_rules:{},
        transform_rules:{},
        compression_rules:{},
        upload_rules:{},
        name:'Test Router',
        description:'This is a Test Router',
        ip_address:'0.0.0.0',
        port:80,
        dicom_ae_title:'Test Router',
        status:'ENABLED'
    },
    {
        routing_rules:{},
        transform_rules:{},
        compression_rules:{},
        upload_rules:{},
        name:'Test Router2',
        description:'This is a Test Router2',
        ip_address:'1.1.1.1',
        port:81,
        dicom_ae_title:'Test Router2',
        status:'DISABLED'
    },
]