import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenu, MatMenuModule } from '@angular/material/menu';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { PacsService } from '../pacs.service';
import { Pacs } from '../pacs.types';
import { Subject, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AttachReportComponent } from '../attach-report/attach-report.component';
import { ShareStudyComponent } from '../share-study/share-study.component'
import { Router } from '@angular/router';
import { HeaderService } from 'app/layout/layouts/horizontal/modern/header.service';
import moment from 'moment';

@Component({
  selector: 'app-share-study-details',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgClass,
    MatMenu,
    MatIconModule,
    MatMenuModule,
  ],
  templateUrl: './share-study-details.component.html',
  styleUrl: './share-study-details.component.scss'
})
export class ShareStudyDetailsComponent implements OnInit, OnDestroy {
  moment = moment;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private _sanitizer = inject(DomSanitizer);
  private _pacsService = inject(PacsService);
  private _dialog = inject(MatDialog);
  private _router = inject(Router);
  private _headerService = inject(HeaderService);
  private _changeDetectorRef = inject(ChangeDetectorRef);
  viewerUrl: SafeUrl;
  studyList: Pacs[] = [];
  currentIndex: number = 0;
  studyDetails: Pacs;
  constructor() {
    this.viewerUrl = this._sanitizer.bypassSecurityTrustResourceUrl("https://dicom-viewer.cacius.com/?study=1.3.12.2.1107.5.2.43.66024.30000018050107081466900000007")
  }

  ngOnInit(): void {
    this._pacsService.shareStudy$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((studyList: Pacs[]) => {
        this.studyList = studyList;
        this.studyDetails = this.studyList[this.currentIndex];
        this.viewerUrl = this.studyDetails?.study_url ? this._sanitizer.bypassSecurityTrustResourceUrl(`${this.studyDetails.study_url}`) : null;
        this.setHeader(this.studyDetails);
        this._changeDetectorRef.markForCheck();
      });
  }

  // Method to go to next study
  nextStudy() {
    if (this.currentIndex >= this.studyList.length - 1) {
      return;
    }
    this.currentIndex += 1;
    this.studyDetails = this.studyList[this.currentIndex];
    this.viewerUrl = this.studyDetails?.study_url ? this._sanitizer.bypassSecurityTrustResourceUrl(`${this.studyDetails.study_url}`) : null;
    this.setHeader(this.studyDetails);
    this._changeDetectorRef.markForCheck();
  }
  // Method to go to prev study
  prevStudy() {
    if (this.currentIndex <= 0) {
      return;
    }
    this.currentIndex -= 1;
    this.studyDetails = this.studyList[this.currentIndex];
    this.viewerUrl = this.studyDetails?.study_url ? this._sanitizer.bypassSecurityTrustResourceUrl(`${this.studyDetails.study_url}`) : null;
    this.setHeader(this.studyDetails);
    this._changeDetectorRef.markForCheck();
  }
  setHeader(studyDetails: Pacs) {
    this._headerService.setLayoutState({
      flag: 'studyDetails',
      data: {
        patientName: studyDetails.patient_name,
        id: studyDetails.id,
        mrn: studyDetails.mrn,
        gender: studyDetails.gender,
        modality: studyDetails.modality,
        exam: studyDetails.exam,
        dos: studyDetails.dos,
        time: studyDetails.time
      },
      backFuncRef: () => {
        this._headerService.setLayoutState({
          flag: 'default',
          data: null,
          backFuncRef: null
        });
        this._router.navigate(['pacs']);
      }
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}

