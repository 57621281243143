export interface FuseAlert {
    type?: FuseAlertType;
    title?: string;
    message?: string;
    actionButtonConfig?: {
        show?: boolean;
        buttonText?: string;
    },
    class?: string;
}

export type FuseAlertAppearance =
    | 'border'
    | 'fill'
    | 'outline'
    | 'soft';

export type FuseAlertType =
    | 'primary'
    | 'accent'
    | 'warn'
    | 'basic'
    | 'info'
    | 'success'
    | 'warning'
    | 'error';
