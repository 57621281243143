
import { FuseAlertType, } from '@fuse/components/alert';
import { Referrer } from '../settings/settings.types';

export enum ExamStatusEnum {
    UNASSIGNED = "UNASSIGNED",
    MI = "MI",
    UNREAD = "UNREAD",
    VERIFYING = "VERIFYING",
    VERIFIED = "VERIFIED",
    TRANSCRIBING = "TRANSCRIBING",
    TRANSCRIBED = "TRANSCRIBED",
    DICTATING = "DICTATING",
    REJECTED = "REJECTED",
    PRELIMINARY = "PRELIMINARY",
    FINALIZING = "FINALIZING",
    FINALIZED = "FINALIZED",
    WRONG_ORDER = "WRONG_ORDER",
    MANAGER_REVIEW_PENDING = "MANAGER_REVIEW_PENDING"
}

export interface Alert {
    alertState: boolean;
    alertData?: { type: FuseAlertType; message: string, actionButtonConfig?: { show: boolean, buttonText: string }, class: string }
}
export interface Report {
    report_id: string;
    modality_id: string;
    reportHtml?: string;
    exam_date: string;
    pdf_url: string;
}
interface Image {
    image_id: string;
    url: string;
}



export interface Doctors {
    doctor_id: string;
    doctor_name: string;
    selected: boolean;
}


export interface ExamsResponse {
    pagination: {
        count: number;
        next: any;
        page: number;
        previous: any;
        size: number;
    };
    results: Exams[];
}



/// NEW
export interface Exams {
    id: string;
    patient: Patient;
    modality: Modality;
    body_part: BodyPart;
    mrn: string;
    img: number;
    referrer?: Referrer;
    department?: null;
    end_time: string;
    exam_date: string;
    exam_assign_date: string;
    exam_finalized_date?: null;
    exam_report: string;
    exam_notes: string;
    scored_by: string;
    radiologist?: User;
    transcriber?: User;
    qa?: User;
    missing_images: boolean;
    images?: [];
    pdf_url: string;
    status: string;
    exam_status: string;
    checked: boolean;
    accession_no?: null;
    role: string;
    title: string;
    location: Location;
    report: any;
    notes?: (null)[] | null;
    documents?: (null)[] | null;
    created_at: string;
    intake_forms: any[] | null,
    study_uid: string | null
}
export interface Location {
    id: string;
    name: string;
    address: string;
    email: string;
    phone_number: string;
    operational_hours_start: string;
    operational_hours_end: string;
    status: string;
}

export interface Patient {
    id: string;
    name: string;
    birth_date: string;
    age: number;
    gender: string;
    residence_address: string;
    city: string;
    zip: string;
    state: string;
    email: string;
    phone: string;
}
export interface Modality {
    id: string;
    modality_code: string;
    modality_name: string;
    modality_type: string;
    status: string;
    created_at: string;
    updated_at: string;
    deleted_at?: null;
}
export interface BodyPart {
    id: string;
    title: string;
    modality_time: string;
    order: number;
    status: string;
    equipment_used: string;
    cpt_code: string;
}

export interface User {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number?: string;
    username: string;
    birth_day?: null;
    gender?: string;
    color_code?: string;
    color_text?: string;
    exam_notifications?: boolean;
    message_notifications?: boolean;
    email_notifications?: boolean;
    role?: null;
    status: string;
    workspace?: string;
}

export interface RadiologyTemplate {
    id: string;
    title: string;
    content: string;
    language: string;
    user: string;
    modality?: null;
    doctor?: null;
    status: string;
    created_at: string;
}

