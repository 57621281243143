/* eslint-disable */
export const categories = [
    {
        id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
        title: 'Active',
        slug: 'web',
    },
    {
        id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        title: 'Inactive',
        slug: 'firebase',
    },
    {
        id: '02f42092-bb23-4552-9ddb-cfdcc235d48f',
        title: 'Archived',
        slug: 'cloud',
    },
    {
        id: '5648a630-979f-4403-8c41-fc9790dea8cd',
        title: 'Archiving',
        slug: 'android',
    },
];
export const workspaces = [
    {
        id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        title: 'Ok Doctor',
        slug: 'basics-of-angular',
        description: 'Introductory course for Angular and framework basics',
        category: 'web',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Jun 28, 2023',
        featured: true,
        progress: {
            currentStep: 3,
            completed: 2,
        },
        workspaceId: '#678111',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/brian-hughes.jpg',
            name: 'Mark'
        }
    },
    {
        id: 'f924007a-2ee9-470b-a316-8d21ed78277f',
        title: 'Carewell',
        slug: 'basics-of-typeScript',
        description: 'Beginner course for Typescript and its basics',
        category: 'web',
        duration: 60,
        totalSteps: 11,
        updatedAt: 'Nov 01, 2023',
        featured: true,
        progress: {
            currentStep: 5,
            completed: 3,
        },
        workspaceId: '#628125',
        technology: 'Radiologist/RIS/PACS',
        status: 'Inactive',
        owner: {
            img: 'assets/images/avatars/female-01.jpg',
            name: 'Julia'
        }
    },
    {
        id: '0c06e980-abb5-4ba7-ab65-99a228cab36b',
        title: 'Vitality Clinic',
        slug: 'android-n-quick-settings',
        description: 'Step by step guide for Android N: Quick Settings',
        category: 'android',
        duration: 120,
        totalSteps: 11,
        updatedAt: 'May 08, 2023',
        featured: false,
        progress: {
            currentStep: 10,
            completed: 1,
        },
        workspaceId: '#678156',
        technology: 'Radiologist/RIS/PACS',
        status: 'Archived',
        owner: {
            img: 'assets/images/avatars/female-02.jpg',
            name: 'Julianna'
        }
    },
    {
        id: '1b9a9acc-9a36-403e-a1e7-b11780179e38',
        title: 'Serenity Health',
        slug: 'build-an-app-for-the-google-assistant-with-firebase',
        description: 'Dive deep into Google Assistant apps using Firebase',
        category: 'firebase',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Jan 09, 2023',
        featured: false,
        progress: {
            currentStep: 4,
            completed: 3,
        },
        workspaceId: '#678101',
        technology: 'Radiologist/RIS/PACS',
        status: 'Archiving',
        owner: {
            img: 'assets/images/avatars/female-03.jpg',
            name: 'Iryna'
        }
    },
    {
        id: '55eb415f-3f4e-4853-a22b-f0ae91331169',
        title: 'Unity Wellness',
        slug: 'keep-sensitive-data-safe-and-private',
        description: 'Learn how to keep your important data safe and private',
        category: 'android',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Jan 14, 2023',
        featured: false,
        progress: {
            currentStep: 6,
            completed: 0,
        },
        workspaceId: '#678102',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/female-04.jpg',
            name: 'Eva'
        }
    },
    {
        id: 'fad2ab23-1011-4028-9a54-e52179ac4a50',
        title: 'Harmony',
        slug: 'manage-your-pivotal-cloud-foundry-apps-using-apigee-Edge',
        description: 'Introductory course for Pivotal Cloud Foundry App',
        category: 'cloud',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Jun 24, 2023',
        featured: false,
        progress: {
            currentStep: 6,
            completed: 0,
        },
        workspaceId: '#678104',
        technology: 'Radiologist/RIS/PACS',
        status: 'Inactive',
        owner: {
            img: 'assets/images/avatars/female-05.jpg',
            name: 'Mark'
        }
    },
    {
        id: 'c4bc107b-edc4-47a7-a7a8-4fb09732e794',
        title: 'Lifespring',
        slug: 'build-a-pwa-using-workbox',
        description: 'Step by step guide for building a PWA using Workbox',
        category: 'web',
        duration: 120,
        totalSteps: 11,
        updatedAt: 'Nov 19, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678103',
        technology: 'Radiologist/RIS/PACS',
        status: 'Archived',
        owner: {
            img: 'assets/images/avatars/female-06.jpg',
            name: 'Mark'
        }
    },
    {
        id: '1449f945-d032-460d-98e3-406565a22293',
        title: 'Apex Wellness Clinic',
        slug: 'cloud-functions-for-firebase',
        description: 'Beginners guide of Firebase Cloud Functions',
        category: 'firebase',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Jul 11, 2023',
        featured: false,
        progress: {
            currentStep: 3,
            completed: 1,
        },
        workspaceId: '#678105',
        technology: 'Radiologist/RIS/PACS',
        status: 'Archiving',
        owner: {
            img: 'assets/images/avatars/female-07.jpg',
            name: 'Mark'
        }
    },
    {
        id: 'f05e08ab-f3e3-4597-a032-6a4b69816f24',
        title: 'Lifespring',
        slug: 'building-a-grpc-service-with-java',
        description: 'Learn more about building a gRPC Service with Java',
        category: 'cloud',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Mar 13, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 1,
        },
        workspaceId: '#678106',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-01.jpg',
            name: 'Mark'
        }
    },
    {
        id: '181728f4-87c8-45c5-b9cc-92265bcd2f4d',
        title: 'Harmony',
        slug: 'looking-at-campaign-finance-with-bigquery',
        description: 'Dive deep into BigQuery: Campaign Finance',
        category: 'cloud',
        duration: 60,
        totalSteps: 11,
        updatedAt: 'Nov 01, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678107',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-02.jpg',
            name: 'Mark'
        }
    },
    {
        id: 'fcbfedbf-6187-4b3b-89d3-1a7cb4e11616',
        title: 'Unity Wellness',
        slug: 'personalize-your-ios-app-with-firebase-user-management',
        description: 'Dive deep into User Management on iOS apps using Firebase',
        category: 'firebase',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Aug 08, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678108',
        technology: 'Radiologist/RIS/PACS',
        status: 'Inactive',
        owner: {
            img: 'assets/images/avatars/male-03.jpg',
            name: 'Mark'
        }
    },
    {
        id: '5213f6a1-1dd7-4b1d-b6e9-ffb7af534f28',
        title: 'Vitality Clinic',
        slug: 'customize-network-topology-with-subnetworks',
        description: 'Dive deep into Network Topology with Subnetworks',
        category: 'web',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'May 12, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678113',
        technology: 'Radiologist/RIS/PACS',
        status: 'Archived',
        owner: {
            img: 'assets/images/avatars/male-04.jpg',
            name: 'Mark'
        }
    },
    {
        id: '02992ac9-d1a3-4167-b70e-8a1d5b5ba253',
        title: 'Harmony',
        slug: 'building-beautiful-uis-with-flutter',
        description: 'Dive deep into Flutter\'s hidden secrets for creating beautiful UIs',
        category: 'web',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Sep 18, 2023',
        featured: false,
        progress: {
            currentStep: 8,
            completed: 2,
        },
        workspaceId: '#678112',
        technology: 'Radiologist/RIS/PACS',
        status: 'Archiving',
        owner: {
            img: 'assets/images/avatars/male-05.jpg',
            name: 'Mark'
        }
    },
    {
        id: '2139512f-41fb-4a4a-841a-0b4ac034f9b4',
        title: 'Firebase Android',
        slug: 'firebase-android',
        description: 'Beginners guide of Firebase for Android',
        category: 'android',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Apr 24, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678118',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-06.jpg',
            name: 'Mark'
        }
    },
    {
        id: '65e0a0e0-d8c0-4117-a3cb-eb74f8e28809',
        title: 'Lifespring ',
        slug: 'simulating-a-thread-network-using-openthread',
        description: 'Introductory course for OpenThread and Simulating a Thread Network',
        category: 'web',
        duration: 45,
        totalSteps: 11,
        updatedAt: 'Jun 05, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678117',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-07.jpg',
            name: 'Mark'
        }
    },
    {
        id: 'c202ebc9-9be3-433a-9d38-7003b3ed7b7a',
        title: 'Apex Wellness Clinic',
        slug: 'your-first-progressive-web-app',
        description: 'Step by step guide for creating a PWA from scratch',
        category: 'web',
        duration: 30,
        totalSteps: 11,
        updatedAt: 'Oct 14, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678116',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-08.jpg',
            name: 'Mark'
        }
    },
    {
        id: '980ae7da-9f77-4e30-aa98-1b1ea594e775',
        title: 'Launch Cloud Datalab',
        slug: 'launch-cloud-datalab',
        description: 'From start to finish: Launch Cloud Datalab',
        category: 'cloud',
        duration: 60,
        totalSteps: 11,
        updatedAt: 'Dec 16, 2023',
        featured: false,
        progress: {
            currentStep: 0,
            completed: 0,
        },
        workspaceId: '#678110',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-09.jpg',
            name: 'Mark'
        }
    },
    {
        id: 'c9748ea9-4117-492c-bdb2-55085b515978',
        title: 'Cloud Firestore',
        slug: 'cloud-firestore',
        description: 'Step by step guide for setting up Cloud Firestore',
        category: 'firebase',
        duration: 90,
        totalSteps: 11,
        updatedAt: 'Apr 04, 2023',
        featured: false,
        progress: {
            currentStep: 2,
            completed: 0,
        },
        workspaceId: '#678121',
        technology: 'Radiologist/RIS/PACS',
        status: 'Active',
        owner: {
            img: 'assets/images/avatars/male-10.jpg',
            name: 'Mark'
        }
    },
];
export const demoCourseContent = `
<p class="lead">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aperiam lab et fugiat id magnam minus nemo quam
    voluptatem. Culpa deleniti explica nisi quod soluta.
</p>
<p>
    Alias animi labque, deserunt distinctio eum excepturi fuga iure labore magni molestias mollitia natus, officia pofro
    quis sunt temporibus veritatis voluptatem, voluptatum. Aut blanditiis esse et illum maxim, obcaecati possimus
    voluptate! Accusamus <em>adipisci</em> amet aperiam, assumenda consequuntur fugiat inventore iusto magnam molestias
    natus necessitatibus, nulla pariatur.
</p>
<p>
    Amet distinctio enim itaque minima minus nesciunt recusandae soluta voluptatibus:
</p>
<blockquote>
    <p>
        Ad aliquid amet asperiores lab distinctio doloremque <code>eaque</code>, exercitationem explicabo, minus mollitia
        natus necessitatibus odio omnis pofro rem.
    </p>
</blockquote>
<p>
    Alias architecto asperiores, dignissimos illum ipsam ipsum itaque, natus necessitatibus officiis, perferendis quae
    sed ullam veniam vitae voluptas! Magni, nisi, quis! A <code>accusamus</code> animi commodi, consectetur distinctio
    eaque, eos excepturi illum laboriosam maiores nam natus nulla officiis perspiciatis rem <em>reprehenderit</em> sed
    tenetur veritatis.
</p>
<p>
    Consectetur <code>dicta enim</code> error eveniet expedita, facere in itaque labore <em>natus</em> quasi? Ad consectetur
    eligendi facilis magni quae quis, quo temporibus voluptas voluptate voluptatem!
</p>
<p>
    Adipisci alias animi <code>debitis</code> eos et impedit maiores, modi nam nobis officia optio perspiciatis, rerum.
    Accusantium esse nostrum odit quis quo:
</p>
<pre><code>h1 a {{'{'}}
    display: block;
    width: 300px;
    height: 80px;
{{'}'}}</code></pre>
<p>
    Accusantium aut autem, lab deleniti eaque fugiat fugit id ipsa iste molestiae,
    <a>necessitatibus nemo quasi</a>
    .
</p>
<hr>
<h2>
    Accusantium aspernatur autem enim
</h2>
<p>
    Blanditiis, fugit voluptate! Assumenda blanditiis consectetur, labque cupiditate ducimus eaque earum, fugiat illum
    ipsa, necessitatibus omnis quaerat reiciendis totam. Architecto, <strong>facere</strong> illum molestiae nihil nulla
    quibusdam quidem vel! Atque <em>blanditiis deserunt</em>.
</p>
<p>
    Debitis deserunt doloremque labore laboriosam magni minus odit:
</p>
<ol>
    <li>Asperiores dicta esse maiores nobis officiis.</li>
    <li>Accusamus aliquid debitis dolore illo ipsam molettiae possimus.</li>
    <li>Magnam mollitia pariatur perspiciatis quasi quidem tenetur voluptatem! Adipisci aspernatur assumenda dicta.</li>
</ol>
<p>
    Animi fugit incidunt iure magni maiores molestias.
</p>
<h3>
    Consequatur iusto soluta
</h3>
<p>
    Aliquid asperiores corporis — deserunt dolorum ducimus eius eligendi explicabo quaerat suscipit voluptas.
</p>
<p>
    Deserunt dolor eos et illum laborum magni molestiae mollitia:
</p>
<blockquote>
    <p>Autem beatae consectetur consequatur, facere, facilis fugiat id illo, impedit numquam optio quis sunt ducimus illo.</p>
</blockquote>
<p>
    Adipisci consequuntur doloribus facere in ipsam maxime molestias pofro quam:
</p>
<figure>
    <img
        src="assets/images/pages/help-center/image-1.jpg"
        alt="">
    <figcaption>
        Accusamus blanditiis labque delectus esse et eum excepturi, impedit ipsam iste maiores minima mollitia, nihil obcaecati
        placeat quaerat qui quidem sint unde!
    </figcaption>
</figure>
<p>
    A beatae lab deleniti explicabo id inventore magni nisi omnis placeat praesentium quibusdam:
</p>
<ul>
    <li>Dolorem eaque laboriosam omnis praesentium.</li>
    <li>Atque debitis delectus distinctio doloremque.</li>
    <li>Fuga illo impedit minima mollitia neque obcaecati.</li>
</ul>
<p>
    Consequ eius eum excepturi explicabo.
</p>
<h2>
    Adipisicing elit atque impedit?
</h2>
<h3>
    Atque distinctio doloremque ea qui quo, repellendus.
</h3>
<p>
    Delectus deserunt explicabo facilis numquam quasi! Laboriosam, magni, quisquam. Aut, blanditiis commodi distinctio, facere fuga
    hic itaque iure labore laborum maxime nemo neque provident quos recusandae sequi veritatis illum inventore iure qui rerum sapiente.
</p>
<h3>
    Accusamus iusto sint aperiam consectetur …
</h3>
<p>
    Aliquid assumenda ipsa nam odit pofro quaerat, quasi recusandae sint! Aut, esse explicabo facilis fugit illum iure magni
    necessitatibus odio quas.
</p>
<ul>
    <li>
        <p><strong>Dolore natus placeat rem atque dignissimos laboriosam.</strong></p>
        <p>
            Amet repudiandae voluptates architecto dignissimos repellendus voluptas dignissimos eveniet itaque maiores natus.
        </p>
        <p>
            Accusamus aliquam debitis delectus dolorem ducimus enim eos, exercitationem fugiat id iusto nostrum quae quos
            recusandae reiciendis rerum sequi temporibus veniam vero? Accusantium culpa, cupiditate ducimus eveniet id maiores modi
            mollitia nisi aliquid dolorum ducimus et illo in.
        </p>
    </li>
    <li>
        <p><strong>Ab amet deleniti dolor, et hic optio placeat.</strong></p>
        <p>
            Accusantium ad alias beatae, consequatur consequuntur eos error eveniet expedita fuga laborum libero maxime nulla pofro
            praesentium rem rerum saepe soluta ullam vero, voluptas? Architecto at debitis, doloribus harum iure libero natus odio
            optio soluta veritatis voluptate.
        </p>
    </li>
    <li>
        <p><strong>At aut consectetur nam necessitatibus neque nesciunt.</strong></p>
        <p>
            Aut dignissimos labore nobis nostrum optio! Dolor id minima velit voluptatibus. Aut consequuntur eum exercitationem
            fuga, harum id impedit molestiae natus neque numquam perspiciatis quam rem voluptatum.
        </p>
    </li>
</ul>
<p>
    Animi aperiam autem labque dolore enim ex expedita harum hic id impedit ipsa laborum modi mollitia non perspiciatis quae ratione.
</p>
<h2>
    Alias eos excepturi facilis fugit.
</h2>
<p>
    Alias asperiores, aspernatur corporis
    <a>delectus</a>
    est
    <a>facilis</a>
    inventore dolore
    ipsa nobis nostrum officia quia, veritatis vero! At dolore est nesciunt numquam quam. Ab animi <em>architecto</em> aut, dignissimos
    eos est eum explicabo.
</p>
<p>
    Adipisci autem consequuntur <code>labque cupiditate</code> dolor ducimus fuga neque nesciunt:
</p>
<pre><code>module.exports = {{'{'}}
    purge: [],
    theme: {{'{'}}
        extend: {{'{}'}},
    },
    variants: {{'{}'}},
    plugins: [],
{{'}'}}</code></pre>
<p>
    Aliquid aspernatur eius fugit hic iusto.
</p>
<h3>
    Dolorum ducimus expedita?
</h3>
<p>
    Culpa debitis explicabo maxime minus quaerat reprehenderit temporibus! Asperiores, cupiditate ducimus esse est expedita fuga hic
    ipsam necessitatibus placeat possimus? Amet animi aut consequuntur earum eveniet.
</p>
<ol>
    <li>
        <strong>Aspernatur at beatae corporis debitis.</strong>
        <ul>
            <li>
                Aperiam assumenda commodi lab dicta eius, “fugit ipsam“ itaque iure molestiae nihil numquam, officia omnis quia
                repellendus sapiente sed.
            </li>
            <li>
                Nulla odio quod saepe accusantium, adipisci autem blanditiis lab doloribus.
            </li>
            <li>
                Explicabo facilis iusto molestiae nisi nostrum obcaecati officia.
            </li>
        </ul>
    </li>
    <li>
        <strong>Nobis odio officiis optio quae quis quisquam quos rem.</strong>
        <ul>
            <li>Modi pariatur quod totam. Deserunt doloribus eveniet, expedita.</li>
            <li>Ad beatae dicta et fugit libero optio quaerat rem repellendus./</li>
            <li>Architecto atque consequuntur corporis id iste magni.</li>
        </ul>
    </li>
    <li>
        <strong>Deserunt non placeat unde veniam veritatis? Odio quod.</strong>
        <ul>
            <li>Inventore iure magni quod repellendus tempora. Magnam neque, quia. Adipisci amet.</li>
            <li>Consectetur adipisicing elit.</li>
            <li>labque eum expedita illo inventore iusto laboriosam nesciunt non, odio provident.</li>
        </ul>
    </li>
</ol>
<p>
    A aliquam architecto consequatur labque dicta doloremque <code>&lt;li&gt;</code> doloribus, ducimus earum, est <code>&lt;p&gt;</code>
    eveniet explicabo fuga fugit ipsum minima minus molestias nihil nisi non qui sunt vel voluptatibus? A dolorum illum nihil quidem.
</p>
<ul>
    <li>
        <p>
            <strong>Laboriosam nesciunt obcaecati optio qui.</strong>
        </p>
        <p>
            Doloremque magni molestias reprehenderit.
        </p>
        <ul>
            <li>Accusamus aperiam blanditiis <code>&lt;p&gt;</code> commodi</li>
            <li>Dolorum ea explicabo fugiat in ipsum</li>
        </ul>
    </li>
    <li>
        <p>
            <strong>Commodi dolor dolorem dolores eum expedita libero.</strong>
        </p>
        <p>
            Accusamus alias consectetur dolores et, excepturi fuga iusto possimus.
        </p>
        <ul>
            <li>
                <p>
                    Accusantium ad alias atque aut autem consequuntur deserunt dignissimos eaque iure <code>&lt;p&gt;</code> maxime.
                </p>
                <p>
                    Dolorum in nisi numquam omnis quam sapiente sit vero.
                </p>
            </li>
            <li>
                <p>
                    Adipisci lab in nisi odit soluta sunt vitae commodi excepturi.
                </p>
            </li>
        </ul>
    </li>
    <li>
        <p>
            Assumenda deserunt distinctio dolor iste mollitia nihil non?
        </p>
    </li>
</ul>
<p>
    Consectetur adipisicing elit dicta dolor iste.
</p>
<h2>
    Consectetur ea natus officia omnis reprehenderit.
</h2>
<p>
    Distinctio impedit quaerat sed! Accusamus
    <a>aliquam aspernatur enim expedita explicabo</a>
    . Libero molestiae
    odio quasi unde ut? Ab exercitationem id numquam odio quisquam!
</p>
<p>
    Explicabo facilis nemo quidem natus tempore:
</p>
<table class="table table-striped table-bordered">
    <thead>
        <tr>
            <th>Wrestler</th>
            <th>Origin</th>
            <th>Finisher</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td>Bret “The Hitman” Hart</td>
            <td>Calgary, AB</td>
            <td>Sharpshooter</td>
        </tr>
        <tr>
            <td>Stone Cold Steve Austin</td>
            <td>Austin, TX</td>
            <td>Stone Cold Stunner</td>
        </tr>
        <tr>
            <td>Randy Savage</td>
            <td>Sarasota, FL</td>
            <td>Elbow Drop</td>
        </tr>
        <tr>
            <td>Vader</td>
            <td>Boulder, CO</td>
            <td>Vader Bomb</td>
        </tr>
        <tr>
            <td>Razor Ramon</td>
            <td>Chuluota, FL</td>
            <td>Razor’s Edge</td>
        </tr>
    </tbody>
</table>
<p>
    A aliquid autem lab doloremque, ea earum eum fuga fugit illo ipsa minus natus nisi <code>&lt;span&gt;</code> obcaecati pariatur
    perferendis pofro <code>suscipit tempore</code>.
</p>
<h3>
    Ad alias atque culpa <code>illum</code> earum optio
</h3>
<p>
    Architecto consequatur eveniet illo in iure laborum minus omnis quibusdam sequi temporibus? Ab aliquid <em>“atque dolores molestiae
    nemo perferendis”</em> reprehenderit saepe.
</p>
<p>
    Accusantium aliquid eligendi est fuga natus, <code>quos</code> vel? Adipisci aperiam asperiores aspernatur consectetur cupiditate
    <a><code>@distinctio/doloribus</code></a>
    et exercitationem expedita, facere facilis illum, impedit inventore
    ipsa iure iusto magnam, magni minus nesciunt non officia possimus quod reiciendis.
</p>
<h4>
    Cupiditate explicabo <code>hic</code> maiores
</h4>
<p>
    Aliquam amet consequuntur distinctio <code>ea</code> est <code>excepturi</code> facere illum maiores nisi nobis non odit officiis
    quisquam, similique tempora temporibus, tenetur ullam <code>voluptates</code> adipisci aperiam deleniti <code>doloremque</code>
    ducimus <code>eos</code>.
</p>
<p>
    Ducimus qui quo tempora. lab enim explicabo <code>hic</code> inventore qui soluta voluptates voluptatum? Asperiores consectetur
    delectus dolorem fugiat ipsa pariatur, quas <code>quos</code> repellendus <em>repudiandae</em> sunt aut blanditiis.
</p>
<h3>
    Asperiores aspernatur autem error praesentium quidem.
</h3>
<h4>
    Ad blanditiis commodi, doloribus id iste <code>repudiandae</code> vero vitae.
</h4>
<p>
    Atque consectetur lab debitis enim est et, facere fugit impedit, possimus quaerat quibusdam.
</p>
<p>
    Dolorem nihil placeat quibusdam veniam? Amet architecto at consequatur eligendi eveniet excepturi hic illo impedit in iste magni maxime
    modi nisi nulla odio placeat quidem, quos rem repellat similique suscipit voluptate voluptates nobis omnis quo repellendus.
</p>
<p>
    Assumenda, eum, minima! Autem consectetur fugiat iste sit! Nobis omnis quo repellendus.
</p>
`;
export const demoCourseSteps = [
    {
        order: 0,
        title: 'Invoices',
        subtitle: 'Introducing the library and how it works',
        content: `<h2 class="text-2xl sm:text-3xl">Introduction</h1> ${demoCourseContent}`,
    },
    {
        order: 1,
        title: 'Subscriptions',
        subtitle: 'Where to find the sample code and how to access it',
        content: `<h2 class="text-2xl sm:text-3xl">Get the sample code</h1> ${demoCourseContent}`,
    },
    {
        order: 2,
        title: 'Requests',
        subtitle: 'How to create a basic Firebase project and how to run it locally',
        content: `<h2 class="text-2xl sm:text-3xl">Create a Firebase project and Set up your app</h1> ${demoCourseContent}`,
    },
    {
        order: 3,
        title: 'Users',
        subtitle: 'Setting up the Firebase CLI to access command line tools',
        content: `<h2 class="text-2xl sm:text-3xl">Install the Firebase Command Line Interface</h1> ${demoCourseContent}`,
    },
    {
        order: 4,
        title: 'Deployment',
        subtitle: 'How to build, push and run the project remotely',
        content: `<h2 class="text-2xl sm:text-3xl">Deploy and run the web app</h1> ${demoCourseContent}`,
    },
    {
        order: 5,
        title: 'Documents',
        subtitle: 'Introducing the Functions and Functions Directory',
        content: `<h2 class="text-2xl sm:text-3xl">The Functions Directory</h1> ${demoCourseContent}`,
    },
];
export const sessionUsers = [
    {
        id: '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        name: 'Ethan Johnson',
        date: 'ethan.williams@example.com',
        role: 'Salesperson',
        lastSession: '1 day ago',
        status: 'Active'
    },
    {
        id: 'f924007a-2ee9-470b-a316-8d21ed78277f',
        name: 'Brandon Miller',
        date: 'noah.wilson@example.com',
        role: 'Salesperson',
        lastSession: '1 day ago',
        status: 'Inactive'
    },
    {
        id: '0c06e980-abb5-4ba7-ab65-99a228cab36b',
        name: 'Mason Brown',
        date: 'liam.thompson@example.com',
        role: 'Salesperson',
        lastSession: '2 day ago',
        status: 'Active'
    },
    {
        id: '1b9a9acc-9a36-403e-a1e7-b11780179e38',
        name: 'Tyler Smith',
        date: 'samantha.johnson@example.com',
        role: 'Salesperson',
        lastSession: '3 day ago',
        status: 'Inactive'
    },
    {
        id: '55eb415f-3f4e-4853-a22b-f0ae91331169',
        name: 'Connor Williams',
        date: 'ethan.williams@example.com',
        role: 'Salesperson',
        lastSession: '3 day ago',
        status: 'Deactivated'
    },
    {
        id: 'fad2ab23-1011-4028-9a54-e52179ac4a50',
        name: 'Mason Brown',
        date: 'liam.thompson@example.com',
        role: 'Salesperson',
        lastSession: '4 day ago',
        status: 'Removed'
    },
];
export const demoSessions = [
    {
        device: 'Chrome on macOS',
        location: 'California 123.123.123.123',
        lastSession: '1 min ago. 22 May, 2024',
        deviceType: 'desktop'
    },
    {
        device: 'Chrome on macOS',
        location: 'Rome 24.456.355.98',
        lastSession: '15 min ago 22 May, 2024',
        deviceType: 'desktop'
    },
    {
        device: 'Safari on iPhone',
        location: 'Rome 24.456.355.98',
        lastSession: '1 day ago 21 May, 2024',
        deviceType: 'mobile'
    },
];
export const documents = [
    {
        id: '694e4e5f-f25f-471b-bd0e-26b1d4f64028',
        name: 'Promotional email request',
        date: 'May 22,2024',
        time: '2 min ago',
        format: 'PDF'
    },
    {
        id: '694e4e5f-f25f-410b-bd0e-26b1d4f64029',
        name: 'Promotional email request',
        date: 'May 22,2024',
        time: '15 min ago',
        format: 'PDF'
    },
    {
        id: '694e4e5f-f21f-470b-bd0e-26b1d4f64128',
        name: 'Promotional email request',
        date: 'May 21,2024',
        time: '22 hours ago',
        format: 'PDF'
    },
    {
        id: '694e4e5f-f15f-470b-bd0e-26b1d4f64228',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '694e4e5g-f25f-470b-bd0e-26b1d4f64328',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '694e4e5h-f25f-470b-bd0e-26b1d4f64428',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '694e4e5k-f25f-470b-bd0e-26b1d4f64528',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '699e4e5f-f25f-470b-bd0e-26b1d4f64028',
        name: 'Promotional email request',
        date: 'May 22,2024',
        time: '2 min ago',
        format: 'PDF'
    },
    {
        id: '698e4e5f-f25f-470b-bd0e-26b1d4f64029',
        name: 'Promotional email request',
        date: 'May 22,2024',
        time: '15 min ago',
        format: 'PDF'
    },
    {
        id: '697e4e5f-f25f-470b-bd0e-26b1d4f64128',
        name: 'Promotional email request',
        date: 'May 21,2024',
        time: '22 hours ago',
        format: 'PDF'
    },
    {
        id: '664e4e5f-f25f-470b-bd0e-26b1d4f64228',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '654e4e5f-f25f-470b-bd0e-26b1d4f64328',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '644e4e5f-f25f-470b-bd0e-26b1d4f64428',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '634e4e5f-f25f-470b-bd0e-26b1d4f64528',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '624e4e5f-f25f-470b-bd0e-26b1d4f64028',
        name: 'Promotional email request',
        date: 'May 22,2024',
        time: '2 min ago',
        format: 'PDF'
    },
    {
        id: '614e4e5f-f25f-470b-bd0e-26b1d4f64029',
        name: 'Promotional email request',
        date: 'May 22,2024',
        time: '15 min ago',
        format: 'PDF'
    },
    {
        id: '194e4e5f-f25f-470b-bd0e-26b1d4f64128',
        name: 'Promotional email request',
        date: 'May 21,2024',
        time: '22 hours ago',
        format: 'PDF'
    },
    {
        id: '294e4e5f-f25f-470b-bd0e-26b1d4f64228',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '394e4e5f-f25f-470b-bd0e-26b1d4f64328',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '494e4e5f-f25f-470b-bd0e-26b1d4f64428',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
    {
        id: '594e4e5f-f25f-470b-bd0e-26b1d4f64528',
        name: 'Promotional email request',
        date: 'May 20,2024',
        time: '1 day ago',
        format: 'PDF'
    },
];

export const deployment = {
    deploymentName: 'Create',
    startTime: '4.07.2023/12:23PM',
    subscription: 'cacius',
    correlationId: '#48693746',
    deploymentData: [
        {
            id: '694e4e5f-f25f-471b-bd0e-26b1d4f64028',
            salesperson: 'cacius-circle-cv-1/AADLoginForWindows',
            date: 'MicrosoftCompute/virtualMachines/extensions',
            action: 'Ok'
        },
        {
            id: '694e4e5f-f25f-410b-bd0e-26b1d4f64029',
            salesperson: 'cacius-circle-cv-1',
            date: 'MicrosoftCompute/virtualMachines',
            action: 'Ok'
        },
        {
            id: '694e4e5f-f21f-470b-bd0e-26b1d4f64128',
            salesperson: 'cacius-circle-cv-1898_z1',
            date: 'Microsoft.Network/networkInterfaces',
            action: 'Created'
        },
        {
            id: '694e4e5f-f15f-470b-bd0e-26b1d4f64228',
            salesperson: 'cacius-circle-cv-1_DataDisk_1',
            date: 'MicrosoftCompute/disks',
            action: 'Ok'
        },
        {
            id: '694e4e5g-f25f-470b-bd0e-26b1d4f64328',
            salesperson: 'cacius-circle-cv-1-nsg',
            date: 'Microsoft.Network/networSecurityGroups',
            action: 'Created'
        },
        {
            id: '694e4e5h-f25f-470b-bd0e-26b1d4f64428',
            salesperson: 'cacius-circle-cv-1-ip',
            date: 'Microsoft.Network/publicIpAddresses',
            action: 'Ok'
        },
    ]
}

export const patientsData = [
    {
        full_name: 'Ronald Richards',
        email: 'ronald.richards@example.com',
        birthDate: '03.16.1943',
        scanId: '6247866',
        address: '123 Main Street, Anytown, USA',
        city: 'Anytown',
        zip: '035587',
        country: 'USA',
        policyNumber: 'XYZ123456789',
        authorizationNumber: 'ABC987654321',
        provider: 'Kaiser Permanente',
        status: 'Active',
        phone: '(239) 555-0108',
        gender: 'Male',
        age: '52',
        emergencyContact: 'Ava, Wife',
        weight: 52,
        height: 183,
        smoking: 'No',
        emergencyContactNum: '(234) 567-8901',
        id: '5648a630-979f-4403-8c41-fc9790dea8cd'
    },
    {
        full_name: 'Emily Johnson',
        email: 'emily.johnson@example.com',
        birthDate: '05.22.1980',
        scanId: '2378956',
        address: '456 Elm Street, Othertown, USA',
        policyNumber: 'XYZ987654321',
        authorizationNumber: 'ABC123456789',
        provider: 'Aetna',
        status: 'Inactive',
        phone: '(239) 555-0199',
        gender: 'Female',
        age: '44',
        id: '9821b7c5-7a4e-46a2-b8b5-1d2f8b2147f5'
    },
    {
        full_name: 'Michael Smith',
        email: 'michael.smith@example.com',
        birthDate: '11.15.1975',
        scanId: '9856237',
        address: '789 Oak Street, Sometown, USA',
        policyNumber: 'XYZ456789123',
        authorizationNumber: 'ABC654321987',
        provider: 'Cigna',
        status: 'Merge',
        phone: '(239) 555-0145',
        gender: 'Male',
        age: '48',
        id: '7164f96a-93d6-42b4-a90b-5c6ff35798b8'
    },
    {
        full_name: 'Linda Davis',
        email: 'linda.davis@example.com',
        birthDate: '08.30.1967',
        scanId: '3489562',
        address: '101 Pine Street, Thistown, USA',
        policyNumber: 'XYZ789123456',
        authorizationNumber: 'ABC321654987',
        provider: 'UnitedHealthcare',
        status: 'Delete',
        phone: '(239) 555-0112',
        gender: 'Female',
        age: '56',
        id: '5c73ab42-8f3b-456b-994c-2ad0e6d8d287'
    },
    {
        full_name: 'David Martinez',
        email: 'david.martinez@example.com',
        birthDate: '01.10.1990',
        scanId: '1029384',
        address: '202 Cedar Street, Mytown, USA',
        policyNumber: 'XYZ321654987',
        authorizationNumber: 'ABC789123456',
        provider: 'Blue Cross Blue Shield',
        status: 'Archived',
        phone: '(239) 555-0177',
        gender: 'Male',
        age: '34',
        id: '39efc1d4-0e2f-49d5-8c8d-9f4f79f1e9a3'
    },
    {
        full_name: 'Sarah Lee',
        email: 'sarah.lee@example.com',
        birthDate: '07.25.1985',
        scanId: '5647382',
        address: '303 Birch Street, Yourtown, USA',
        policyNumber: 'XYZ654321789',
        authorizationNumber: 'ABC987123654',
        provider: 'Humana',
        status: 'Badge',
        phone: '(239) 555-0163',
        gender: 'Female',
        age: '39',
        id: 'f5ac33d2-6e2c-4e70-957f-1e9a5c6b12d3'
    },
    {
        full_name: 'James Wilson',
        email: 'james.wilson@example.com',
        birthDate: '04.18.1973',
        scanId: '7896541',
        address: '404 Willow Street, Anycity, USA',
        policyNumber: 'XYZ123789456',
        authorizationNumber: 'ABC456987123',
        provider: 'Anthem',
        status: 'Active',
        phone: '(239) 555-0138',
        gender: 'Male',
        age: '51',
        id: 'b7f7c6d2-4a7a-4f6d-8969-3c5d5dce9b3e'
    },
    {
        full_name: 'Jessica Brown',
        email: 'jessica.brown@example.com',
        birthDate: '09.12.1992',
        scanId: '2165473',
        address: '505 Maple Street, Somewhere, USA',
        policyNumber: 'XYZ654789321',
        authorizationNumber: 'ABC123654789',
        provider: 'WellCare',
        status: 'Inactive',
        phone: '(239) 555-0159',
        gender: 'Female',
        age: '31',
        id: '7a7e4d6e-3b8d-49cf-b8e8-c8b8d7e8f8d6'
    },
    {
        full_name: 'Robert Miller',
        email: 'robert.miller@example.com',
        birthDate: '06.05.1982',
        scanId: '3482165',
        address: '606 Spruce Street, Mycity, USA',
        policyNumber: 'XYZ789456123',
        authorizationNumber: 'ABC654987321',
        provider: 'Molina Healthcare',
        status: 'Active',
        phone: '(239) 555-0184',
        gender: 'Male',
        age: '42',
        id: '9d3f6b4d-3c6e-42b5-8c8f-8c7e5d6d8c4e'
    },
    {
        full_name: 'Laura Walker',
        email: 'laura.walker@example.com',
        birthDate: '02.14.1977',
        scanId: '4598732',
        address: '707 Oakwood Street, Thistown, USA',
        policyNumber: 'XYZ321789654',
        authorizationNumber: 'ABC789654321',
        provider: 'Oscar Health',
        status: 'Inactive',
        phone: '(239) 555-0172',
        gender: 'Female',
        age: '47',
        id: '1d4f6c7d-5b7e-4c8d-8b8e-8d8e6c8e7d5f'
    },
    {
        full_name: 'Daniel Harris',
        email: 'daniel.harris@example.com',
        birthDate: '10.28.1965',
        scanId: '5978241',
        address: '808 Pinewood Street, Yourcity, USA',
        policyNumber: 'XYZ654123987',
        authorizationNumber: 'ABC123987654',
        provider: 'Aetna',
        status: 'Active',
        phone: '(239) 555-0193',
        gender: 'Male',
        age: '58',
        id: '2e4f6b7d-5c7e-4d8d-9b8f-8e7e5f8e7d6d'
    },
    {
        full_name: 'Sophia Martinez',
        email: 'sophia.martinez@example.com',
        birthDate: '12.22.1988',
        scanId: '6985712',
        address: '909 Cedarwood Street, Thiscity, USA',
        policyNumber: 'XYZ789321654',
        authorizationNumber: 'ABC321987654',
        provider: 'Cigna',
        status: 'Inactive',
        phone: '(239) 555-0123',
        gender: 'Female',
        age: '35',
        id: '3f4e6d8d-6c7e-4e8d-9c9f-8e8e7d7e7d6e'
    },
    {
        full_name: 'Christopher Clark',
        email: 'christopher.clark@example.com',
        birthDate: '07.08.1983',
        scanId: '7519348',
        address: '1010 Birchwood Street, Thatcity, USA',
        policyNumber: 'XYZ123654789',
        authorizationNumber: 'ABC987321654',
        provider: 'Blue Cross Blue Shield',
        status: 'Active',
        phone: '(239) 555-0168',
        gender: 'Male',
        age: '41',
        id: '4f6e7d8d-6d8e-4f8d-9d9f-8e9e8d8d8e7f'
    },
    {
        full_name: 'Mia Scott',
        email: 'mia.scott@example.com',
        birthDate: '11.19.1991',
        scanId: '1897324',
        address: '1111 Maplewood Street, Thistown, USA',
        policyNumber: 'XYZ456321789',
        authorizationNumber: 'ABC654123987',
        provider: 'Humana',
        status: 'Inactive',
        phone: '(239) 555-0191',
        gender: 'Female',
        age: '32',
        id: '5f6e8d7d-7d8e-4e8d-9d9e-8e8e9d8e7d7f'
    },
    {
        full_name: 'Alexander Anderson',
        email: 'alexander.anderson@example.com',
        birthDate: '03.25.1986',
        scanId: '7328194',
        address: '1212 Sprucewood Street, Othertown, USA',
        policyNumber: 'XYZ321456987',
        authorizationNumber: 'ABC987123654',
        provider: 'UnitedHealthcare',
        status: 'Active',
        phone: '(239) 555-0146',
        gender: 'Male',
        age: '38',
        id: '6f7e8d8d-7e8e-4f9d-9e9f-8e8e9d7e8d8f'
    },
    {
        full_name: 'Isabella King',
        email: 'isabella.king@example.com',
        birthDate: '08.14.1974',
        scanId: '2981347',
        address: '1313 Oakwood Street, Somecity, USA',
        policyNumber: 'XYZ654987321',
        authorizationNumber: 'ABC321654987',
        provider: 'Anthem',
        status: 'Inactive',
        phone: '(239) 555-0182',
        gender: 'Female',
        age: '50',
        id: '7f8e9d7d-7e9e-4e9d-9e8f-8e9e8d7e7d7f'
    },
    {
        full_name: 'Elijah Lewis',
        email: 'elijah.lewis@example.com',
        birthDate: '06.07.1979',
        scanId: '8912347',
        address: '1414 Birchwood Street, Anycity, USA',
        policyNumber: 'XYZ987123654',
        authorizationNumber: 'ABC654321987',
        provider: 'WellCare',
        status: 'Active',
        phone: '(239) 555-0190',
        gender: 'Male',
        age: '45',
        id: '8f7e9d8d-7e9e-4e8d-9e8f-8e9e7d8e8d8f'
    },
    {
        full_name: 'Amelia Young',
        email: 'amelia.young@example.com',
        birthDate: '04.12.1984',
        scanId: '4382917',
        address: '1515 Cedarwood Street, Yourcity, USA',
        policyNumber: 'XYZ123987654',
        authorizationNumber: 'ABC321987654',
        provider: 'Molina Healthcare',
        status: 'Inactive',
        phone: '(239) 555-0137',
        gender: 'Female',
        age: '40',
        id: '9f8e8d7d-7f9e-4e8d-9e9f-8e9e8d7e7e8f'
    },
    {
        full_name: 'Lucas White',
        email: 'lucas.white@example.com',
        birthDate: '02.28.1993',
        scanId: '5647391',
        address: '1616 Pinewood Street, Thatcity, USA',
        policyNumber: 'XYZ987654321',
        authorizationNumber: 'ABC654987321',
        provider: 'Oscar Health',
        status: 'Active',
        phone: '(239) 555-0186',
        gender: 'Male',
        age: '31',
        id: 'af8e7d7d-8e9e-4e8d-9d8f-8e9e7d8e7d8f'
    },
    {
        full_name: 'Evelyn Hall',
        email: 'evelyn.hall@example.com',
        birthDate: '01.05.1987',
        scanId: '6812345',
        address: '1717 Maplewood Street, Anycity, USA',
        policyNumber: 'XYZ321654789',
        authorizationNumber: 'ABC123654789',
        provider: 'Aetna',
        status: 'Inactive',
        phone: '(239) 555-0175',
        gender: 'Female',
        age: '37',
        id: 'bf8e8d8d-8f9e-4e9d-9e9f-8e9e7d7e7d9f'
    },
    {
        full_name: 'Benjamin Harris',
        email: 'benjamin.harris@example.com',
        birthDate: '09.23.1981',
        scanId: '7923468',
        address: '1818 Sprucewood Street, Yourtown, USA',
        policyNumber: 'XYZ654987123',
        authorizationNumber: 'ABC321654321',
        provider: 'Cigna',
        status: 'Active',
        phone: '(239) 555-0189',
        gender: 'Male',
        age: '43',
        id: 'cf9e8d7d-9f9e-4e9d-9e8f-8e9e7d8e8d9f'
    }
];

export const appointments = [
    {
        date: '18 Feb 2024, 17: 48',
        facility: 'Horizon Family Practice, 9101 Pine Lane, Orlando, FL 32801, USA',
        modality: 'Dental radiography (dental X-rays)',
        body_part: 'Head',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '2 Apr 2024, 09: 36',
        facility: 'Summit Medical Clinic, 1012 Cedar Street, San Francisco, CA 94102, USA',
        modality: 'Densitometry (measurement of bone density)',
        body_part: 'Left hand',
        status: 'Upcoming',
        doctorName: 'Savannah Nguyen'
    },
    {
        date: '5 Jan 2024, 14:22',
        facility: 'Lakeside Medical Center, 1234 Lake Road, Miami, FL 33101, USA',
        modality: 'MRI (Magnetic Resonance Imaging)',
        body_part: 'Brain',
        status: 'Upcoming',
        doctorName: 'Theresa Webb'
    },
    {
        date: '10 Feb 2024, 10:15',
        facility: 'Green Valley Hospital, 5678 Valley Avenue, Denver, CO 80201, USA',
        modality: 'CT Scan (Computed Tomography)',
        body_part: 'Chest',
        status: 'Upcoming',
        doctorName: 'Courtney Henry'
    },
    {
        date: '15 Mar 2024, 08:45',
        facility: 'Ocean View Clinic, 9101 Coastal Highway, Los Angeles, CA 90001, USA',
        modality: 'Ultrasound',
        body_part: 'Abdomen',
        status: 'Previous',
        doctorName: 'Smith Roy'
    },
    {
        date: '20 Apr 2024, 13:30',
        facility: 'Mountain Peak Hospital, 1012 Summit Street, Seattle, WA 98101, USA',
        modality: 'X-ray',
        body_part: 'Leg',
        status: 'Upcoming',
        doctorName: 'Steve Normane'
    },
    {
        date: '25 May 2024, 11:00',
        facility: 'Sunrise Health Center, 1234 Dawn Avenue, Austin, TX 73301, USA',
        modality: 'Mammography',
        body_part: 'Breast',
        status: 'Upcoming',
        doctorName: 'Luo Fox'
    },
    {
        date: '30 Jun 2024, 09:20',
        facility: 'Riverbend Medical, 5678 River Road, Chicago, IL 60601, USA',
        modality: 'PET Scan (Positron Emission Tomography)',
        body_part: 'Whole body',
        status: 'Previous',
        doctorName: 'Chris Fox'
    },
    {
        date: '5 Jul 2024, 14:50',
        facility: 'Evergreen Hospital, 9101 Forest Lane, Portland, OR 97201, USA',
        modality: 'Endoscopy',
        body_part: 'Stomach',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '10 Aug 2024, 16:10',
        facility: 'Desert Springs Clinic, 1012 Oasis Street, Phoenix, AZ 85001, USA',
        modality: 'Colonoscopy',
        body_part: 'Colon',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '15 Sep 2024, 13:35',
        facility: 'Prairie Health Center, 1234 Plains Avenue, Kansas City, MO 64101, USA',
        modality: 'EKG (Electrocardiogram)',
        body_part: 'Heart',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '20 Oct 2024, 12:25',
        facility: 'Canyon Medical Center, 5678 Cliff Road, Salt Lake City, UT 84101, USA',
        modality: 'DEXA Scan (Bone Density Scan)',
        body_part: 'Spine',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '25 Nov 2024, 10:45',
        facility: 'Blossom Health Clinic, 9101 Flower Lane, Sacramento, CA 94201, USA',
        modality: 'Fluoroscopy',
        body_part: 'Lung',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '30 Dec 2024, 09:30',
        facility: 'Horizon Family Practice, 1234 Pine Lane, Orlando, FL 32801, USA',
        modality: 'Nuclear Medicine',
        body_part: 'Thyroid',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '5 Jan 2024, 08:50',
        facility: 'Summit Medical Clinic, 5678 Cedar Street, San Francisco, CA 94102, USA',
        modality: 'Angiography',
        body_part: 'Blood vessels',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '10 Feb 2024, 14:15',
        facility: 'Lakeside Medical Center, 9101 Lake Road, Miami, FL 33101, USA',
        modality: 'MRI (Magnetic Resonance Imaging)',
        body_part: 'Spine',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '15 Mar 2024, 12:35',
        facility: 'Green Valley Hospital, 1012 Valley Avenue, Denver, CO 80201, USA',
        modality: 'CT Scan (Computed Tomography)',
        body_part: 'Pelvis',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '20 Apr 2024, 11:05',
        facility: 'Ocean View Clinic, 1234 Coastal Highway, Los Angeles, CA 90001, USA',
        modality: 'Ultrasound',
        body_part: 'Heart',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '25 May 2024, 15:40',
        facility: 'Mountain Peak Hospital, 5678 Summit Street, Seattle, WA 98101, USA',
        modality: 'X-ray',
        body_part: 'Hand',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '30 Jun 2024, 14:25',
        facility: 'Sunrise Health Center, 9101 Dawn Avenue, Austin, TX 73301, USA',
        modality: 'Mammography',
        body_part: 'Breast',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '5 Jul 2024, 09:55',
        facility: 'Riverbend Medical, 1012 River Road, Chicago, IL 60601, USA',
        modality: 'PET Scan (Positron Emission Tomography)',
        body_part: 'Brain',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '10 Aug 2024, 08:20',
        facility: 'Evergreen Hospital, 1234 Forest Lane, Portland, OR 97201, USA',
        modality: 'Endoscopy',
        body_part: 'Esophagus',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '15 Sep 2024, 11:15',
        facility: 'Desert Springs Clinic, 5678 Oasis Street, Phoenix, AZ 85001, USA',
        modality: 'Colonoscopy',
        body_part: 'Colon',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '20 Oct 2024, 10:30',
        facility: 'Prairie Health Center, 9101 Plains Avenue, Kansas City, MO 64101, USA',
        modality: 'EKG (Electrocardiogram)',
        body_part: 'Heart',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '25 Nov 2024, 14:45',
        facility: 'Canyon Medical Center, 1234 Cliff Road, Salt Lake City, UT 84101, USA',
        modality: 'DEXA Scan (Bone Density Scan)',
        body_part: 'Hip',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    },
    {
        date: '30 Dec 2024, 16:00',
        facility: 'Blossom Health Clinic, 5678 Flower Lane, Sacramento, CA 94201, USA',
        modality: 'Fluoroscopy',
        body_part: 'Lung',
        status: 'Previous',
        doctorName: 'Robert Fox'
    },
    {
        date: '5 Jan 2025, 13:10',
        facility: 'Horizon Family Practice, 9101 Pine Lane, Orlando, FL 32801, USA',
        modality: 'Nuclear Medicine',
        body_part: 'Bone',
        status: 'Upcoming',
        doctorName: 'Robert Fox'
    }
]

export const messages = [
    {
        consultant: 'Dr. William Scott',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 25 April 2024. Please note that you should arrive at the clinic in advance by 1 PM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Jun 8, 2024',
        type: 'email',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. William Scott',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 25 April 2024. Please note that you should arrive at the clinic in advance by 1 PM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Jun 8, 2024',
        type: 'sms',
        doctorName: 'Savannah Nguyen'
    },
    {
        consultant: 'Dr. Emily Clarke',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 15 August 2024. Please arrive at the clinic by 10 AM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Jul 10, 2024',
        type: 'email',
        doctorName: 'Theresa Webb'
    },
    {
        consultant: 'Dr. Emily Clarke',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 15 August 2024. Please arrive at the clinic by 10 AM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Jul 10, 2024',
        type: 'sms',
        doctorName: 'Courtney Henry'
    },
    {
        consultant: 'Dr. Michael Johnson',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 10 September 2024. Please arrive at the clinic by 2 PM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Aug 5, 2024',
        type: 'email',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. Michael Johnson',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 10 September 2024. Please arrive at the clinic by 2 PM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Aug 5, 2024',
        type: 'sms',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. Sarah Martinez',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 20 October 2024. Please arrive at the clinic by 11 AM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Sep 15, 2024',
        type: 'email',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. Sarah Martinez',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 20 October 2024. Please arrive at the clinic by 11 AM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Sep 15, 2024',
        type: 'sms',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. David Lee',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 30 November 2024. Please arrive at the clinic by 9 AM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Oct 25, 2024',
        type: 'email',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. David Lee',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 30 November 2024. Please arrive at the clinic by 9 AM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Oct 25, 2024',
        type: 'sms',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. Lisa Brown',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 5 December 2024. Please arrive at the clinic by 3 PM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Nov 1, 2024',
        type: 'email',
        doctorName: 'Robert Fox'
    },
    {
        consultant: 'Dr. Lisa Brown',
        message: 'This is a reminder of your upcoming appointment at our clinic. Your scheduled date and time: 5 December 2024. Please arrive at the clinic by 3 PM to prepare for your visit. If you have any questions or are unable to attend the appointment, please notify us in advance at [Clinic Phone Number]. Thank you for your attention.',
        timestamp: 'Nov 1, 2024',
        type: 'sms',
        doctorName: 'Robert Fox'
    }
]

export const exams = [
    {
        dos: '18 Feb 2024, 17:48',
        mrn: '0018247365',
        mod: 'XR',
        exam: 'Dental radiography (dental X-rays)',
        assignee: 'Brooklyn Simmons',
        referrer: 'Savannah Nguyen',
        facility: 'Sunrise Health Clinic, 5678 Oak Avenue, Denver, CO 80202, USA',
        img: '5',
        accession: '0073192485',
    },
    {
        dos: '9 Feb 2024, 16:10',
        mrn: '0076583492',
        mod: 'CT',
        exam: 'Computed tomography (CT)',
        assignee: 'Eleanor Pena',
        referrer: 'Marvin McKinney',
        facility: 'Desert Springs Medical, 1234 Desert Road, Phoenix, AZ 85001, USA',
        img: '1',
        accession: '0093456287',
    },
    {
        dos: '3 Feb 2024, 13:05',
        mrn: '0035684790',
        mod: 'MRI',
        exam: 'Magnetic resonance imaging (MRI)',
        assignee: 'Arlene McCoy',
        referrer: 'Jane Cooper',
        facility: 'Lakeside Health Center, 4321 Lakeview Drive, Springfield, IL 62701, USA',
        img: '6',
        accession: '0078634120',
    },
    {
        dos: '2 Apr 2024, 09:36',
        mrn: '001746298',
        mod: 'CT',
        exam: 'Densitometry (measurement of bone density)',
        assignee: 'Leslie Alexander',
        referrer: 'Savannah Nguyen',
        facility: 'Ocean Breeze Health, 9876 Seaside Avenue, Miami, FL 33101, USA',
        img: '11',
        accession: '0084293765',
    },
    {
        dos: '12 Apr 2024, 18:02',
        mrn: '0009783542',
        mod: 'MRI',
        exam: 'Magnetic resonance imaging (MRI)',
        assignee: 'Devon Lane',
        referrer: 'Annette Black',
        facility: 'Desert Springs Medical, 1234 Desert Road, Phoenix, AZ 85001, USA',
        img: '9',
        accession: '0054972386',
    },
    {
        dos: '3 May 2024, 17:45',
        mrn: '0016745238',
        mod: 'PET',
        exam: 'Fluoroscopy (for lung disease screening)',
        assignee: 'Jacob Jones',
        referrer: 'Floyd Miles',
        facility: 'Sunrise Health Clinic, 5678 Oak Avenue, Denver, CO 80202, USA',
        img: '14',
        accession: '0029784563',
    },
    {
        dos: '12 Feb 2024, 11:37',
        mrn: '0057638492',
        mod: 'XR',
        exam: 'X-ray radiography',
        assignee: 'Darlene Robertson',
        referrer: 'Guy Hawkins',
        facility: 'Horizon Family Practice, 1357 Horizon Road, San Diego, CA 92101, USA',
        img: '2',
        accession: '0078945632',
    },
    {
        dos: '24 Feb 2024, 19:25',
        mrn: '0009876543',
        mod: 'MRI',
        exam: 'Mammography (for breast examination)',
        assignee: 'Dianne Russell',
        referrer: 'Cameron Williamson',
        facility: 'Sunrise Health Clinic, 5678 Oak Avenue, Denver, CO 80202, USA',
        img: '19',
        accession: '0085947231',
    },
    {
        dos: '19 Mar 2024, 10:18',
        mrn: '0073982610',
        mod: 'PET',
        exam: 'Positron emission tomography (PET)',
        assignee: 'Cody Fisher',
        referrer: 'Albert Flores',
        facility: 'Sunrise Health Clinic, 5678 Oak Avenue, Denver, CO 80202, USA',
        img: '21',
        accession: '0047592831',
    },
    {
        dos: '2 Mar 2024, 08:03',
        mrn: '0005678903',
        mod: 'PET',
        exam: 'Densitometry (measurement of bone density)',
        assignee: 'Courtney Henry',
        referrer: 'Kristin Watson',
        facility: 'Horizon Family Practice, 1357 Horizon Road, San Diego, CA 92101, USA',
        img: '16',
        accession: '0087324956',
    },
    {
        dos: '6 Apr 2024, 13:47',
        mrn: '0024785936',
        mod: 'CT',
        exam: 'Computed tomography (CT)',
        assignee: 'Esther Howard',
        referrer: 'Darrell Steward',
        facility: 'Mountain View Hospital, 2468 Mountain Road, Denver, CO 80202, USA',
        img: '8',
        accession: '0067459832',
    },
]

export const studyData = [
    {
        mrn: '0018247365',
        mod: 'XR',
        exam: 'Dental radiography (dental X-rays)',
        assignee: 'Olivia^Adams',
        referrer: 'Layla^Rivera',
        dos: '09-18-1968',
        time: '01:15 AM',
        img: '9',
        reports: '0',
    },
    {
        mrn: '0076583492',
        mod: 'CT',
        exam: 'Computed tomography (CT)',
        assignee: 'Sophia^Hall',
        referrer: 'Michael^Johnson',
        dos: '03-06-1996',
        time: '12:35 PM',
        img: '21',
        reports: '0',
    },
    {
        mrn: '0035684790',
        mod: 'MRI',
        exam: 'Magnetic resonance imaging (MRI)',
        assignee: 'Alexander^Scott',
        referrer: 'Charles^Peterson',
        dos: '02-17-1979',
        time: '06:05 AM',
        img: '14',
        reports: '0',
    },
    {
        mrn: '0001746298',
        mod: 'CT',
        exam: 'Densitometry (measurement of bone density)',
        assignee: 'Benjamin^Lopez',
        referrer: 'Ava^Campbell',
        dos: '04-10-1994',
        time: '11:30 AM',
        img: '6',
        reports: '0',
    },
    {
        mrn: '0009783542',
        mod: 'MRI',
        exam: 'Magnetic resonance imaging (MRI)',
        assignee: 'Karen^Harris',
        referrer: 'Chloe^Hill',
        dos: '08-14-1966',
        time: '05:40 AM',
        img: '15',
        reports: '0',
    },
    {
        mrn: '0016745238',
        mod: 'PET',
        exam: 'Fluoroscopy (for lung disease screening)',
        assignee: 'Mia^Reed',
        referrer: 'William^Martinez',
        dos: '07-31-1964',
        time: '11:20 PM',
        img: '22',
        reports: '0',
    },
    {
        mrn: '0057638492',
        mod: 'XR',
        exam: 'X-ray radiography',
        assignee: 'Grace^Hernandez',
        referrer: 'Matthew^White',
        dos: '06-19-1970',
        time: '08:20 AM',
        img: '5',
        reports: '0',
    },
    {
        mrn: '0009876543',
        mod: 'MRI',
        exam: 'Mammography (for breast examination)',
        assignee: 'Andrew^Thomas',
        referrer: 'Samuel^King',
        dos: '05-27-1980',
        time: '09:40 AM',
        img: '1',
        reports: '0',
    },
    {
        mrn: '0079382610',
        mod: 'PET',
        exam: 'Positron emission tomography (PET)',
        assignee: 'Lisa^Thompson',
        referrer: 'Zoe^Powell',
        dos: '02-05-1960',
        time: '06:25 PM',
        img: '18',
        reports: '6',
    },
    {
        mrn: '0005678903',
        mod: 'PET',
        exam: 'Densitometry (measurement of bone density)',
        assignee: 'Emily^Clark',
        referrer: 'Hannah^Gomez',
        dos: '01-19-1983',
        time: '06:55 PM',
        img: '7',
        reports: '0',
    },
    {
        mrn: '0024785936',
        mod: 'CT',
        exam: 'Computed tomography (CT)',
        assignee: 'Nicholas^Young',
        referrer: 'Anna^Anderson',
        dos: '11-25-1995',
        time: '09:05 PM',
        img: '16',
        reports: '0',
    },
    {
        mrn: '0045609872',
        mod: 'XR',
        exam: 'X-ray radiography',
        assignee: 'Kenneth^Kelly',
        referrer: 'Christopher^Thomas',
        dos: '10-21-1998',
        time: '07:45 PM',
        img: '10',
        reports: '0',
    },
    {
        mrn: '0085947231',
        mod: 'MRI',
        exam: 'Fluoroscopy (for lung disease screening)',
        assignee: 'Sarah^Davis',
        referrer: 'Jonathan^Mitchell',
        dos: '11-07-1985',
        time: '10:50 PM',
        img: '8',
        reports: '0',
    },
    {
        mrn: '0047592381',
        mod: 'CT',
        exam: 'Densitometry (measurement of bone density)',
        assignee: 'Sofia^Kim',
        referrer: 'Anthony^Lewis',
        dos: '09-27-1986',
        time: '04:20 AM',
        img: '4',
        reports: '0',
    },
    {
        mrn: '0026583947',
        mod: 'PET',
        exam: 'Ultrasound examination (ultrasound)',
        assignee: 'Nicholas^Young',
        referrer: 'James^Taylor',
        dos: '07-09-1977',
        time: '04:35 AM',
        img: '20',
        reports: '0',
    },
    {
        mrn: '0019382654',
        mod: 'MRI',
        exam: 'Angiography (for vascular study)',
        assignee: 'Ella^Morgan',
        referrer: 'Robert^Rogers',
        dos: '10-12-1971',
        time: '07:25 AM',
        img: '2',
        reports: '0',
    },
]

export const invoicesData = [
    {
        amount: '$1000 USD',
        invoiceNumber: '0018247365',
        billingDate: '18 Feb 2024, 17:48',
        servise: 'Dental radiography (dental X-rays)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0076583492',
        billingDate: '9 Feb 2024, 16:10',
        servise: 'Computed tomography (CT)',
        status: 'Not paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0035684790',
        billingDate: '3 Feb 2024, 13:05',
        servise: 'Magnetic resonance imaging (MRI)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0001746298',
        billingDate: '2 Apr 2024, 09:36',
        servise: 'Densitometry (measurement of bone density)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0009783542',
        billingDate: '12 Apr 2024, 18:02',
        servise: 'Magnetic resonance imaging (MRI)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0016745238',
        billingDate: '3 May 2024, 17:45',
        servise: 'Fluoroscopy (for lung disease screening)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0057638492',
        billingDate: '12 Feb 2024, 11:37',
        servise: 'X-ray radiography',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0009876543',
        billingDate: '24 Feb 2024, 19:25',
        servise: 'Mammography (for breast examination)',
        status: 'Not paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0079382610',
        billingDate: '19 Mar 2024, 10:18',
        servise: 'Positron emission tomography (PET)',
        status: 'Not paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0005678903',
        billingDate: '2 Mar 2024, 08:03',
        servise: 'Densitometry (measurement of bone density)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0024785936',
        billingDate: '6 Apr 2024, 13:47',
        servise: 'Computed tomography (CT)',
        status: 'Not paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0045609872',
        billingDate: '29 Apr 2024, 11:12',
        servise: 'X-ray radiography',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0085947231',
        billingDate: '27 Mar 2024, 16:21',
        servise: 'Fluoroscopy (for lung disease screening)',
        status: 'Not paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0047592381',
        billingDate: '21 Mar 2024, 14:59',
        servise: 'Densitometry (measurement of bone density)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0026583947',
        billingDate: '13 Mar 2024, 15:50',
        servise: 'Ultrasound examination (ultrasound)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0019382654',
        billingDate: '6 Mar 2024, 12:29',
        servise: 'Angiography (for vascular study)',
        status: 'Not paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0019382654',
        billingDate: '6 Mar 2024, 12:29',
        servise: 'Angiography (for vascular study)',
        status: 'Paid',
    },
    {
        amount: '$1000 USD',
        invoiceNumber: '0019382654',
        billingDate: '6 Mar 2024, 12:29',
        servise: 'Angiography (for vascular study)',
        status: 'Paid',
    }
]

export const appointmentEvents = [
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setHours(9, 0)),
        end: new Date(new Date().setHours(9, 30)),
        patientName: 'John Doe',
        resourceId: '1',
        bookingSlot: 'Mon, Apr 23 - 1:25 - 2:20',
        modality: 'MRT',
        doctor: 'Alexander Young',
        appointmentId: '1234465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only. Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'af8e7d7d-8e9e-4e8d-9d8f-8e9e7d8e7d8f',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setHours(10, 0)),
        end: new Date(new Date().setHours(10, 45)),
        patientName: 'Jane Smith',
        resourceId: '1',
        bookingSlot: 'Mon, Apr 23 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '3434465441',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'b1a2c3d4-e5f6-7890-1234-56789abcdef0',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(11, 0)),
        end: new Date(new Date().setHours(11, 30)),
        patientName: 'Alice Johnson',
        resourceId: '1',
        bookingSlot: 'Mon, Apr 23 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '4444465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'c2b3a4d5-f6e7-8901-2345-6789abcdef01',
    },
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setHours(12, 0)),
        end: new Date(new Date().setHours(12, 45)),
        patientName: 'Bob Brown',
        resourceId: '2',
        bookingSlot: 'Mon, Apr 23 - 12:00 - 12:45',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '5534465441',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'd3c4b5a6-e7f8-9012-3456-789abcdef012',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setHours(13, 0)),
        end: new Date(new Date().setHours(13, 30)),
        patientName: 'Carol White',
        resourceId: '2',
        bookingSlot: 'Mon, Apr 23 - 13:00 - 13:30',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '6634465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'e4d5c6b7-f8e9-0123-4567-89abcdef0123',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(14, 0)),
        end: new Date(new Date().setHours(14, 45)),
        patientName: 'David Green',
        resourceId: '2',
        bookingSlot: 'Mon, Apr 23 - 14:00 - 14:45',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '7734465441',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'f5e6d7c8-9012-3456-789a-bcdef0123456',
    },
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setHours(15, 0)),
        end: new Date(new Date().setHours(15, 30)),
        patientName: 'Eve Black',
        resourceId: '3',
        bookingSlot: 'Mon, Apr 23 - 15:00 - 15:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '8884465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '0123f6e7-d8c9-b012-3456-789abcdef678',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setHours(16, 0)),
        end: new Date(new Date().setHours(16, 45)),
        patientName: 'Frank Blue',
        resourceId: '3',
        bookingSlot: 'Mon, Apr 23 - 16:00 - 16:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '9934465441',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '1234a5b6-c7d8-e901-2345-6789bcdef012',
    },

    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(17, 0)),
        end: new Date(new Date().setHours(17, 30)),
        patientName: 'Grace Yellow',
        resourceId: '3',
        bookingSlot: 'Mon, Apr 23 - 17:00 - 17:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465412',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '2345b6c7-d8e9-f012-3456-789acdef0123',
    },
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setHours(9, 0)),
        end: new Date(new Date().setHours(9, 30)),
        patientName: 'Henry Purple',
        resourceId: '4',
        bookingSlot: 'Mon, Apr 23 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465445',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '3456c7d8-e9f0-1234-5678-9abcdef01234',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setHours(10, 0)),
        end: new Date(new Date().setHours(10, 45)),
        patientName: 'Ivy Orange',
        resourceId: '4',
        bookingSlot: 'Mon, Apr 23 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465555',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '4567d8e9-f012-3456-789a-bcdef0123456',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(11, 0)),
        end: new Date(new Date().setHours(11, 30)),
        patientName: 'Jack Pink',
        resourceId: '4',
        bookingSlot: 'Mon, Apr 23 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465666',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '5678e9f0-1234-5678-9abc-def012345678',
    },
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setHours(12, 0)),
        end: new Date(new Date().setHours(12, 45)),
        patientName: 'Kelly Brown',
        resourceId: '5',
        bookingSlot: 'Mon, Apr 23 - 12:00 - 12:45',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465477',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '6789f0a1-2345-6789-abcd-ef0123456789',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setHours(13, 0)),
        end: new Date(new Date().setHours(13, 30)),
        patientName: 'Liam Green',
        resourceId: '5',
        bookingSlot: 'Mon, Apr 23 - 13:00 - 13:30',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465488',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '789a0b1c-3456-789a-bcde-f0123456789a',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(14, 0)),
        end: new Date(new Date().setHours(14, 45)),
        patientName: 'Mia Blue',
        resourceId: '5',
        bookingSlot: 'Mon, Apr 23 - 14:00 - 14:45',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465449',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '89ab1c2d-4567-89ab-cdef-0123456789ab',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(14, 45)),
        end: new Date(new Date().setHours(15, 0)),
        patientName: 'Mia Blue',
        resourceId: '5',
        bookingSlot: 'Mon, Apr 23 - 14:45 - 15:00',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465999',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '9abc2d3e-5678-9abc-def0-123456789abc',
    },
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setHours(15, 0)),
        end: new Date(new Date().setHours(15, 30)),
        patientName: 'Noah Yellow',
        resourceId: '6',
        bookingSlot: 'Mon, Apr 23 - 15:00 - 15:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465555',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'abc3d4e5-6789-abcd-ef01-23456789abcd',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setHours(16, 0)),
        end: new Date(new Date().setHours(16, 45)),
        patientName: 'Olivia Purple',
        resourceId: '6',
        bookingSlot: 'Mon, Apr 23 - 16:00 - 16:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'bcd4e5f6-789a-bcde-f012-3456789abcde',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setHours(17, 0)),
        end: new Date(new Date().setHours(17, 30)),
        patientName: 'Paul Orange',
        resourceId: '6',
        bookingSlot: 'Mon, Apr 23 - 17:00 - 17:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'cde5f6a7-89ab-cdef-0123-456789abcdef',
    },
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(9, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(9, 30),
        patientName: 'John Doe',
        resourceId: '1',
        bookingSlot: 'Tue, Apr 23 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'def6a7b8-9abc-def0-1234-56789abcdef0',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(10, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(10, 45),
        patientName: 'Jane Smith',
        resourceId: '1',
        bookingSlot: 'Tue, Apr 23 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'ef7b8c9d-abc0-1234-5678-9abcdef01234',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(11, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(11, 30),
        patientName: 'Alice Johnson',
        resourceId: '1',
        bookingSlot: 'Tue, Apr 23 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'f7c8d9a0-bcd1-2345-6789-abcdef012345',
    },

    // Tuesday events
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 2)).setHours(9, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 2)).setHours(9, 30),
        patientName: 'Bob Brown',
        resourceId: '2',
        bookingSlot: 'Wed, Apr 24 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '08d9a0b1-cde2-3456-789a-bcdef0123456',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 2)).setHours(10, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 2)).setHours(10, 45),
        patientName: 'Carol White',
        resourceId: '2',
        bookingSlot: 'Wed, Apr 24 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '19e0b2c3-def3-4567-89ab-cdef01234567',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 2)).setHours(11, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 2)).setHours(11, 30),
        patientName: 'David Green',
        resourceId: '2',
        bookingSlot: 'Wed, Apr 24 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '2a1b3c4d-ef45-6789-abcd-ef0123456789',
    },
    // Wednesday events
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 3)).setHours(9, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 3)).setHours(9, 30),
        patientName: 'Eve Black',
        resourceId: '3',
        bookingSlot: 'Thu, Apr 25 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '3b2c4d5e-0f12-3456-789a-bcdef0123456',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 3)).setHours(10, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 3)).setHours(10, 45),
        patientName: 'Frank Blue',
        resourceId: '3',
        bookingSlot: 'Thu, Apr 25 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '4c3d5e6f-1a23-4567-89ab-cdef01234567',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 3)).setHours(11, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 3)).setHours(11, 30),
        patientName: 'Grace Yellow',
        resourceId: '3',
        bookingSlot: 'Thu, Apr 25 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '5d4e6f7a-2b34-5678-9abc-def012345678',
    },

    // Thursday events
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4)).setHours(9, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4)).setHours(9, 30),
        patientName: 'Henry Purple',
        resourceId: '4',
        bookingSlot: 'Fri, Apr 26 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '6e5f7a8b-3c45-6789-abcd-ef0123456789',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4)).setHours(10, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4)).setHours(10, 45),
        patientName: 'Ivy Orange',
        resourceId: '4',
        bookingSlot: 'Fri, Apr 26 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '7f6a8b9c-4d56-789a-bcde-f0123456789a',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4)).setHours(11, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 4)).setHours(11, 30),
        patientName: 'Jack Pink',
        resourceId: '4',
        bookingSlot: 'Fri, Apr 26 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '8a7b9c0d-5e67-89ab-cdef-0123456789ab',
    },
    // Friday events
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 5)).setHours(9, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 5)).setHours(9, 30),
        patientName: 'Kelly Brown',
        resourceId: '5',
        bookingSlot: 'Sat, Apr 27 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: '9b8c0d1e-6f78-9abc-def0-123456789abc',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 5)).setHours(10, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 5)).setHours(10, 45),
        patientName: 'Liam Green',
        resourceId: '5',
        bookingSlot: 'Sat, Apr 27 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'ac9d1e2f-7a89-bcde-f012-3456789abcd',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 5)).setHours(11, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 5)).setHours(11, 30),
        patientName: 'Mia Blue',
        resourceId: '5',
        bookingSlot: 'Sat, Apr 27 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'bd0e2f3a-8b9c-def0-1234-56789abcdef',
    },
    // Saturday events
    {
        title: 'Abdominal ultrasound',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).setHours(9, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).setHours(9, 30),
        patientName: 'Noah Yellow',
        resourceId: '6',
        bookingSlot: 'Sun, Apr 28 - 09:00 - 09:30',
        modality: 'Ultrasound',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'ce1f3a4b-9c0d-ef01-2345-6789abcdef0',
    },
    {
        title: 'Cardiac MRI',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).setHours(10, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).setHours(10, 45),
        patientName: 'Olivia Purple',
        resourceId: '6',
        bookingSlot: 'Sun, Apr 28 - 10:00 - 10:45',
        modality: 'MRI',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'df2a4b5c-0d1e-f012-3456-789abcdef012',
    },
    {
        title: 'CT Scan',
        start: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).setHours(11, 0),
        end: new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 6)).setHours(11, 30),
        patientName: 'Paul Orange',
        resourceId: '6',
        bookingSlot: 'Sun, Apr 28 - 11:00 - 11:30',
        modality: 'CT',
        doctor: 'Alexander Young',
        appointmentId: '2434465441',
        status: 'booked',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Ensure that all patient information is handled in compliance with HIPAA and other relevant privacy laws. Access should be restricted to authorized personnel only.',
        id: 'e3b5c6d7-1f2a-3456-789a-bcdef0123456',
    },

];

export const appointmentData = [
    {
        exam: '70482- W/O & W/ CONTRAST',
        modality: 'MRT',
        doctor: 'Alexander Young',
        patientName: 'Christopher Henderson',
        appointmentId: '2434465441',
        date: new Date(new Date().setHours(9, 0)),
        time: '11:00 AM - 11:30 AM',
        status: 'Booked',
        gender: 'Male',
        age: '77',
        paymentStatus: 'Not paid',
        bookedBy: 'Elizabeth Anderson',
        bookedByRole: 'Admin',
        specialConsideration: 'Any accommodations needed for disabilities or language interpretation services.',
        referringPhysician: 'Abigail King',
        reasonForReferral: 'Specific reason or diagnosis necessitating the imaging study',
        previousImagingStudies: 'Information about any related previous imaging studies, which may be necessary for comparison.',
        currentSymptoms: 'Symptoms that are the reason for the imaging study.',
        medicalHistory: 'Relevant past medical conditions that might affect the imaging process or interpretation.',
        medications: 'Including any blood thinners or medications that might affect the procedure.',
        id: '6247866'
    }
]

export const routingRules = [
    {
        name: 'Route studies to Greenborough PACS',
        description: 'This is test',
        source_AE: 'TWH_CT (19000,5000)',
        local_AE: 'AdvaPACS:5000',
        destination_AEs: 'Legacy PACS',
        bind_AE: 'TLH:5000',
        level: 'Series',
        priority: 99,
        status: 'Enabled',
        id: 'bf8e8d8d-8f9e-4e9d-9e9f-8e9e7d7e7d9f'
    },
    {
        name: 'Greenborough PACS',
        description: 'This is test',
        source_AE: 'TWH_CT (19000,5000)',
        local_AE: 'AdvaPACS:5000',
        destination_AEs: 'Legacy PACS',
        bind_AE: 'TLH:7000',
        level: 'Series',
        priority: 12,
        status: 'Disabled',
        id: 'bf8e8d8d-8f9e-4e9d-9e9f-8e9e7d7e7d9k'
    }
]

export const transformRules = [
    {
        name: 'Add EXT prefix',
        description: 'Adds an EX...',
        local_AEs: 'TLH:5000',
        source_AEs: 'ED CT (orth...',
        trigger: 'RECEIVE',
        destination_AEs: '',
        target: 'AdvaPACS Cloud',
        gateway: '',
        status: 'Enabled',
        id: 'bf8e8d8d-8f9e-4e9d-9e9f-8e9e7d7e7d9j',
    },
    {
        name: 'Standardize Contr...',
        description: 'This modifi...',
        local_AEs: 'AdvaPACS...',
        source_AEs: 'TWH_CT (1...',
        trigger: 'RECEIVE',
        destination_AEs: '',
        target: 'AdvaPACS Cloud',
        gateway: '',
        status: 'Enabled',
        id: 'bf8e8d8d-8f9e-4e9d-9e9f-8e9e7d7e7d9l',
    }
];

export const myWorkspaces = [
    {
        email: 'example023@gmail.com',
        workspaces: [
            {
                name: 'OkDoctor',
                id: '1212121',
                members: 23,
                status: 'accepted',
                bgColor: '#0691DE',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'Health Haven Clinic',
                id: '2323232',
                members: 23,
                status: 'accepted',
                bgColor: '#046C4E',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'Vitality Clinic',
                id: '565656565',
                members: 23,
                status: 'accepted',
                bgColor: '#6C2BD9',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'Serenity Health',
                id: '3252525',
                members: 23,
                status: 'accepted',
                bgColor: '#BF125D',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'Carewell',
                id: '646664646',
                members: 23,
                status: 'pending',
                bgColor: '#d9672b',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'WellCare',
                id: '7878787878',
                members: 23,
                status: 'pending',
                bgColor: '#d9ae2b',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'FinTest',
                id: '8989898',
                members: 23,
                status: 'accepted',
                bgColor: '#794a4a',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
        ]
    },
    {
        email: 'example777@health.com',
        workspaces: [
            {
                name: 'Vitality Clinic',
                id: '41414141',
                members: 23,
                status: 'accepted',
                bgColor: '#6C2BD9',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'Serenity Health',
                id: '252525252',
                members: 23,
                status: 'accepted',
                bgColor: '#BF125D',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'Carewell',
                id: '36363636',
                members: 23,
                status: 'pending',
                bgColor: '#d9672b',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'WellCare',
                id: '4747474',
                members: 23,
                status: 'pending',
                bgColor: '#d9ae2b',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
            {
                name: 'FinTest',
                id: '5858585',
                members: 23,
                status: 'accepted',
                bgColor: '#794a4a',
                profileImages: [
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg',
                    'user-profile.svg'
                ]
            },
        ]
    }
]