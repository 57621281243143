export const userActivityLog = [
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'RS',
        statusText: 'LogOut',
        statusColor: 'grey',
        details: 'A LogOut in system: 5:30pm, Session duration: 7 hours'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'PR',
        statusText: 'Appointment',
        statusColor: 'red',
        details: 'Rescheduling appointment #245446541'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'Patient',
        statusColor: 'blue',
        details: `Established or updated the patient's medical history regarding chronic conditions.`
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'Order',
        statusColor: 'yellow',
        details: 'Viewing patient data - Robert Kalinski'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    },
    {
        id: '123456789UAL1',
        submit_date: '18 Feb 2024, 17: 48',
        user_name: 'Tom Holland',
        ip:'192.168.4.1',
        role: 'FR',
        statusText: 'LogIn',
        statusColor: 'green',
        details: 'A Login in system: 7:30am'
    }
]