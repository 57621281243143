/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id: 'example',
        title: 'Example',
        type: 'basic',
        icon: 'heroicons_outline:chart-pie',
        link: '/example'
    }
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: '',
        link: '/example'
    },
    // {
    //     id   : 'workspace',
    //     title: 'Workspaces',
    //     type : 'basic',
    //     icon : '',
    //     link : '/workspaces'
    // },
    {
        id: 'appointments',
        title: 'Appointments',
        type: 'basic',
        icon: '',
        link: '/appointments'
    },
    {
        id: 'patients',
        title: 'Patients',
        type: 'basic',
        icon: '',
        link: '/patients'
    },
    // {
    //     id   : 'invoices',
    //     title: 'Invoices',
    //     type : 'basic',
    //     icon : '',
    //     link : '/invoices'
    // },
    {
        id: 'exams',
        title: 'Exams',
        type: 'basic',
        icon: '',
        link: '/exams'
    },
    // {
    //     id   : 'support',
    //     title: 'Support',
    //     type : 'basic',
    //     icon : '',
    //     link : '/example'
    // },    
    {
        id   : 'pacs',
        title: 'PACS',
        type: 'basic',
        icon: '',
        link: '/pacs'
    },
    {
        id: 'activity',
        title: 'Activity Logs',
        type: 'basic',
        icon: '',
        link: '/activity'
    },
    {
        id: 'settings',
        title: 'Settings',
        type: 'basic',
        icon: '',
        link: '/settings'
    }
];
