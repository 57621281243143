export const teams = [
    {
        name: 'Jane Cooper',
        gender: 'male',
        birthDay: new Date(),
        email: 'cethan.williams@example.com',
        phoneNumber: '+1-213-456-7890',
        roles: 'Admin',
        color: 'Blue',
        colorCode: '#20AFFF',
        status: 'active'
    },
    {
        name: 'Jane Cooper',
        gender: 'male',
        birthDay: new Date(),
        email: 'cethan.williams@example.com',
        phoneNumber: '+1-213-456-7890',
        roles: 'Admin',
        color: 'Blue',
        colorCode: '#20AFFF',
        status: 'inactive'
    },
    {
        name: 'Jane Cooper',
        gender: 'male',
        birthDay: new Date(),
        email: 'cethan.williams@example.com',
        phoneNumber: '+1-213-456-7890',
        roles: 'Admin',
        color: 'Blue',
        colorCode: '#20AFFF',
        status: 'active'
    },
    {
        name: 'Jane Cooper',
        gender: 'male',
        birthDay: new Date(),
        email: 'cethan.williams@example.com',
        phoneNumber: '+1-213-456-7890',
        roles: 'Admin',
        color: 'Blue',
        colorCode: '#20AFFF',
        status: 'inactive'
    },
    {
        name: 'Jane Cooper',
        gender: 'male',
        birthDay: new Date(),
        email: 'cethan.williams@example.com',
        phoneNumber: '+1-213-456-7890',
        roles: 'Admin',
        color: 'Blue',
        colorCode: '#20AFFF',
        status: 'active'
    },
    {
        name: 'Jane Cooper',
        gender: 'male',
        birthDay: new Date(),
        email: 'cethan.williams@example.com',
        phoneNumber: '+1-213-456-7890',
        roles: 'Admin',
        color: 'Blue',
        colorCode: '#20AFFF',
        status: 'inactive'
    }
]