import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AuthService } from 'app/core/auth/auth.service';
import { User } from 'app/core/user/user.types';
import { Notification, NotificationResponse } from 'app/layout/common/notifications/notifications.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, map, Observable, ReplaySubject, startWith, Subject, switchMap, take, tap } from 'rxjs';
import { json } from 'stream/consumers';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
    private _notifications: ReplaySubject<Notification[]> = new ReplaySubject<Notification[]>(1);
    private _authService = inject(AuthService);
    private _user: User;
    private _baseUrl = environment.apiURL;
    // Notification socket 
    private _socketUrl: string = environment.socketUrl;
    private _socket: WebSocket;
    private _socketStatus = new BehaviorSubject<'connected' | 'error' | 'closed'>('closed');
    private _notificationMsg = new Subject<Notification>();

    constructor(private _httpClient: HttpClient) {

        this._authService.userChanged$.pipe(startWith(true)).subscribe(
            () => {
                this._authService.getUserdata().pipe(take(1)).subscribe(userData => {
                    this._user = userData;
                    // get existing notifications
                    this.getAll().pipe(take(1)).subscribe();
                    // connect notification socket
                    this.initNotificationSocket();
                });
            }
        );

        //reset cache
        this._authService.logout$.subscribe(() => {
            this.closeNotificationSocket();
            this.resetNotificationCache();
        });

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<Notification[]> {
        return this._notifications.asObservable();
    }

    get socketStatus$() {
        return this._socketStatus.asObservable();
    }

    get notificationMsg$() {
        return this._notificationMsg.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resetNotificationCache() {
        this._notifications.next([]);
    }
    /**
     * Get all notifications
     */
    getAll(): Observable<NotificationResponse> {
        return this._httpClient.get<NotificationResponse>(`${this._baseUrl}notification/list/${this._user.id}/`).pipe(
            tap((notifications) => {
                this._notifications.next(notifications.results);
            }),
        );
    }

    /**
     * Create a notification
     *
     * @param notification
     */
    create(notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.post<Notification>('api/common/notifications', { notification }).pipe(
                map((newNotification) => {
                    // Update the notifications with the new notification
                    this._notifications.next([...notifications, newNotification]);

                    // Return the new notification from observable
                    return newNotification;
                }),
            )),
        );
    }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(id: string, notification: Notification): Observable<Notification> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.patch<Notification>('api/common/notifications', {
                id,
                notification,
            }).pipe(
                map((updatedNotification: Notification) => {
                    // Find the index of the updated notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Update the notification
                    notifications[index] = updatedNotification;

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated notification
                    return updatedNotification;
                }),
            )),
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(id: string): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.delete<boolean>('api/common/notifications', { params: { id } }).pipe(
                map((isDeleted: boolean) => {
                    // Find the index of the deleted notification
                    const index = notifications.findIndex(item => item.id === id);

                    // Delete the notification
                    notifications.splice(index, 1);

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the deleted status
                    return isDeleted;
                }),
            )),
        );
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(): Observable<boolean> {
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this._httpClient.get<boolean>('api/common/notifications/mark-all-as-read').pipe(
                map((isUpdated: boolean) => {
                    // Go through all notifications and set them as read
                    notifications.forEach((notification, index) => {
                        notifications[index].read = true;
                    });

                    // Update the notifications
                    this._notifications.next(notifications);

                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }

    /**
     * Mark selected notifications as read or archive
     */
    markAsReadOrArchiveSelectedNotifications(selectedNotifications: Notification[], action: 'archive' | 'read'): Observable<boolean> {
        const selectedNotificationIds = selectedNotifications.map(n => n.id);
        const payload = {
            ids: selectedNotificationIds
        };
        payload[action] = true;

        return this.notifications$.pipe(
            take(1),
            tap((notifications) => {
                notifications.forEach((notification) => {
                    if (selectedNotificationIds.includes(notification.id)) {
                        notification[action] = true;
                    }
                });

                notifications = notifications.map(notification => {
                    notification.selected = false;
                    return notification;
                });
                this._notifications.next(notifications);
            }),
            switchMap(notifications => this._httpClient.post<boolean>(`${this._baseUrl}notification/update/`, payload).pipe(
                map((isUpdated: boolean) => {
                    // Return the updated status
                    return isUpdated;
                }),
            )),
        );
    }

    // socket init
    initNotificationSocket() {
        this._socket = new WebSocket(`${this._socketUrl}notification/${this._user.id}/?token=${this._authService.accessToken}`);

        this._socket.onopen = async () => {
            console.log('Notification WebSocket connection established');
            this._socketStatus.next('connected');
        };

        this._socket.onclose = () => {
            console.log('Notification WebSocket connection closed');
            this._socketStatus.next('closed');
        };

        this._socket.onerror = (error) => {
            console.error('Notification WebSocket error:', error);
            this._socketStatus.error('error');
        };

        this._socket.onmessage = (event) => {
            try {
                const notification: string = event.data;
                const parseNotification: Notification = JSON.parse(notification);
                this._notificationMsg.next(parseNotification);

            } catch (error) {
                console.log("error", error);
            }
        };
    }

    // close socket
    closeNotificationSocket() {
        if (this._socket.readyState === WebSocket.OPEN) {
            this._socket.close();
        }
    }
}
