export const forms = [
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'active'
    },
    {
        name: 'Questionnaire for Patients with Implanted Medical Devices',
        owner_name: 'Cameron Williamson',
        creating_date: '21 Mar 2024, 14: 59',
        status: 'inactive'
    },
]