export const SMTPConfiguration = {
    use_SMTP: true,
    reports_Email: 'support@lifeimgaingfla.com',
    AMTP_Server: 'SJDKffckszsjvbk',
    SMTP_Username: 'dgfldfjgkmfb2f4b5fc42fb',
    SMTP_Password: 'Test Password',
    phone: '+1-213-456-7890',
    use_TLS: false
}

export const TwilioConfigutation = {
    use_Twilio: false,
    twilio_Sender: 'support@lifeimgaingfla.com',
    twilio_Account_SID: 'SJDKffckszsjvbk',
    twilio_Authenticatio_Token: '1fdg2d4g2d4g52d4fg522zf4gv5zd4ffv'
}