import { NgClass, NgFor, NgIf, NgStyle, UpperCasePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { ChooseWorkspaceDialogComponent } from './choose-workspace-dialog/choose-workspace-dialog.component';
import { catchError, of, Subject, takeUntil, tap } from 'rxjs';
import { AccountDetails, Workspace } from 'app/modules/admin/settings/settings.types';
import { FilterPipe } from './filter.pipe';
import { SettingsService } from 'app/modules/admin/settings/settings.service';
import { FuseAlertService } from '@fuse/components/alert';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'my-workspaces',
  templateUrl: './my-workspaces.component.html',
  styleUrls: ['./my-workspaces.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgStyle,
    NgIf,
    NgClass,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    FilterPipe,
    UpperCasePipe,
    MatSelectModule,
  ],
})
export class MyWorkspacesComponent implements OnInit, OnDestroy {
  currentWorkspace: FormControl = new FormControl('');
  isDropdownOpen = false;
  backgroundColors = ['#0691DE', '#046C4E', '#6C2BD9', '#BF125D']
  userData: AccountDetails;
  activeWorkspace: Workspace;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    public dialog: MatDialog,
    private _settingsService: SettingsService,
    private _alertService: FuseAlertService,
    private _authService: AuthService,
    private _changeDetectorRef: ChangeDetectorRef
  ) { }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this._settingsService.accountDetails$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((accountDetails: AccountDetails) => {
        this.userData = accountDetails;
        this.userData.workspaces.map((w, index) => w.bgColor = this.backgroundColors[index % this.backgroundColors.length]);
        this.activeWorkspace = this.userData.workspaces.find(w => w.workspace_id === this.userData.workspace);
        if (this.activeWorkspace) {
          this.currentWorkspace.setValue(this.activeWorkspace);
        }
        this._changeDetectorRef.detectChanges();
      })
  }

  selectWorkspace(value: Workspace) {
    if (value.workspace_id !== this.activeWorkspace.workspace_id) {
      const payload = {
        workspace_name: value.workspace,
        workspace_id: value.workspace_id
      }
      this._authService.switchWorkspace(payload).pipe(
        tap((_) => {
        }), catchError((error) => {
          this._alertService.setAlert({
            alertState: true, alertData: {
              type: 'error',
              message: 'Oops, something went wrong. Unfortunately, the changes were not saved. Please try again.',
              actionButtonConfig: { show: false, buttonText: null },
              class: 'w-auto'
            }
          });
          console.error(error);
          return of(null);
        })
      ).subscribe();
    }
  }

  public onWorkspacePopup(): void {
    this.dialog.open(ChooseWorkspaceDialogComponent, {
      panelClass: 'workspace-dialog',
      disableClose: true,
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}