<form class="text-white">
  <mat-form-field class="workspace-field" [hideRequiredMarker]="true">
    <mat-select #workspaceSelect [formControl]="currentWorkspace" panelClass="custom-workspace-select"
      panelWidth="358px" (openedChange)="isDropdownOpen = $event" (selectionChange)="selectWorkspace($event.value)">
      <mat-select-trigger>
        <div class="flex items-center gap-2 font-host">
          <div [ngStyle]="{'background-color': currentWorkspace.value.bgColor}"
            class="min-w-[22px] h-[22px] rounded text-lg font-medium flex items-center justify-center">
            {{currentWorkspace.value.workspace[0] | uppercase}}
          </div>

          {{currentWorkspace.value.workspace}}

          <mat-icon class="icon-size-6 text-white duration-150" [ngClass]="{'rotate-180': isDropdownOpen}"
            svgIcon="mat_solid:keyboard_arrow_down"></mat-icon>
        </div>
      </mat-select-trigger>

      <div class="text-xl text-white font-medium font-clash mb-4">
        Your workspaces
      </div>

      <div class="flex flex-col max-h-56 overflow-auto pb-[1px]">
        @for (workspace of userData.workspaces; track workspace.workspace_id) {
        <mat-option *ngIf="workspace.status === 'AC'" class="border-b border-[#333844] min-h-14" [value]="workspace">
          <div class="flex items-center gap-3 font-host text-white">
            <div [ngStyle]="{'background-color': workspace.bgColor}"
              class="name-icon min-w-[39px] h-[39px] rounded-lg flex items-center justify-center text-xl font-medium">
              {{workspace.workspace[0] | uppercase}}
            </div>

            <div>
              <div class="text-base font-medium text-white leading-5">{{ workspace.workspace }}</div>
              <div class="text-sm font-normal text-[#9297A7] leading-[18px]">{{ workspace.members }} members</div>
            </div>
          </div>
        </mat-option>
        }
      </div>

      <div class="mt-4">
        <button mat-button (click)="workspaceSelect.toggle();onWorkspacePopup()"
          class="border border-solid border-[#333844] rounded-lg w-full text-base font-bold font-host text-white flex items-center gap-2 hover:bg-[#2F3646]">
          <mat-icon class="icon-size-5 text-white">add</mat-icon>Sign in to another workspace
        </button>
      </div>
    </mat-select>
  </mat-form-field>
</form>