
import { Injectable } from '@angular/core';
import { ExamStatusEnum } from '../exams.types';
import { SettingsService } from '../../settings/settings.service';


@Injectable({ providedIn: 'root' })
export class ActionButtonPermission {

    /**
     * Constructor
     */
    constructor(private settingsService: SettingsService) {
    }

    /**
    * Method to check save button permission based on exam status
    */
    saveButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isManager, isTranscriber, isQA, isRadiologist } = this.settingsService;

        if (isRadiologist && [ExamStatusEnum.UNREAD, ExamStatusEnum.MANAGER_REVIEW_PENDING, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED].includes(exam_status)) {
            return true;
        }

        if (isRadiologist && exam_status === ExamStatusEnum.FINALIZED) {
            return false;
        }

        if (isManager) {
            return false;
        }

        if (isTranscriber && exam_status === ExamStatusEnum.UNASSIGNED) {
            return false;
        }

        if (isQA && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING].includes(exam_status)) {
            return false;
        }

        if (!isRadiologist && exam_status === ExamStatusEnum.FINALIZED) {
            return false;
        }

        return true;
    }

    /**
      * Method to check dictation button permission based on exam status
      */
    dictationButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isManager, isTranscriber, isQA, isRadiologist } = this.settingsService;
        if (isRadiologist && [ExamStatusEnum.UNREAD, ExamStatusEnum.MANAGER_REVIEW_PENDING, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED].includes(exam_status)) {
            return true;
        }

        if (isManager) {
            return false;
        }
        if (isTranscriber && exam_status === ExamStatusEnum.UNASSIGNED) {
            return false;
        }
        if (isQA && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING].includes(exam_status)) {
            return false;
        }

        return true;
    }

    /**
    * Method to check  assign button permission based on exam status
    */
    assignButtonPermission(): boolean {
        const { isManager } = this.settingsService;

        if (!isManager) {
            return false;
        }
        return true
    }

    /**
     * Method to check  status update button permission based on exam status
     */
    statusUpdateButtonPermission(): boolean {
        const { isManager } = this.settingsService;

        if (!isManager) {
            return false;
        }
        return true
    }

    /**
    * Method to check  report details button permission based on exam status
    */
    reportDetailsButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isManager, isTranscriber, isQA, isRadiologist } = this.settingsService;

        if (isRadiologist && [ExamStatusEnum.UNREAD, ExamStatusEnum.MANAGER_REVIEW_PENDING, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED].includes(exam_status)) {
            return true;
        }

        if ([ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED, ExamStatusEnum.FINALIZING, ExamStatusEnum.FINALIZED, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.MANAGER_REVIEW_PENDING].includes(exam_status)) {
            return true;
        }

        if (isManager && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED].includes(exam_status)) {
            return false;
        }
        if (isTranscriber && [ExamStatusEnum.UNASSIGNED].includes(exam_status)) {
            return false;
        }
        if (isQA && [ExamStatusEnum.UNASSIGNED, ExamStatusEnum.UNREAD, ExamStatusEnum.TRANSCRIBING].includes(exam_status)) {
            return false;
        }

        return true;
    }

    /**
    * Method to check  view Report button permission based on exam status
    */
    viewReportButtonPermission(exam_status: ExamStatusEnum): boolean {
        if ([ExamStatusEnum.TRANSCRIBED, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.FINALIZING, ExamStatusEnum.FINALIZED, ExamStatusEnum.MANAGER_REVIEW_PENDING].includes(exam_status)) {
            return true;
        }
        return false;
    }

    /**
    * Method to check star tFinalize button permission based on exam status
    * 
    * ExamStatusEnum.FINALIZING
    */
    startFinalizeButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isRadiologist } = this.settingsService;
        if (isRadiologist && [ExamStatusEnum.UNREAD, ExamStatusEnum.MANAGER_REVIEW_PENDING, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED].includes(exam_status)) {
            return true;
        }
        return false;
    }

    /**
    * Method to check  finalize Report button permission based on exam status
    * 
    * ExamStatusEnum.UNREAD, ExamStatusEnum.MANAGER_REVIEW_PENDING, ExamStatusEnum.PRELIMINARY, ExamStatusEnum.TRANSCRIBING, ExamStatusEnum.TRANSCRIBED
    */
    finalizeReportButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isRadiologist } = this.settingsService;
        if (isRadiologist && [ExamStatusEnum.FINALIZING].includes(exam_status)) {
            return true;
        }
        return false;
    }

    /**
    * Method to check  Regenerate Report permission based on exam status
    */
    regenerateReportButtonPermission(exam_status: ExamStatusEnum): boolean {
        const { isRadiologist, isManager } = this.settingsService;
        if ((isManager || isRadiologist) && [ExamStatusEnum.FINALIZED].includes(exam_status)) {
            return true;
        }
        return false;
    }
}
