// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: true,
    apiURL: 'https://api-dev.cacius.com/api/v1/',
    // apiURL: 'https://api.cacius.com/api/v1/',
    recaptcha: { //TODO change
        siteKey: '6LfKNi0cAAAAACeYwFRY9_d_qjGhpiwYUo5gNW5-',
    },
    FE_Url:'https://app-dev.cacius.com/',
    socketUrl : 'wss://api-dev.cacius.com/ws/'
};
