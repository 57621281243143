<!-- Button -->
<button mat-button class="flex items-center px-2" [matMenuTriggerFor]="userActions">
    <div class="flex items-center">
        <div class="overflow-hidden">
            <div class="text-base text-[#FFFFFF] font-host font-medium leading-6 truncate">
                {{ user?.first_name }} {{ user?.last_name }}
            </div>
        </div>
        <div class="flex items-center justify-center pl-3">
            <mat-icon class="icon-size-6 text-[#FFFFFF]" [svgIcon]="'heroicons_mini:chevron-down'"></mat-icon>
        </div>
    </div>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu" class="bg-darkprimary-100">
    <!-- <button mat-menu-item>
        <span class="flex flex-col leading-none">
            <span>Signed in as</span>
            <span class="mt-1.5 text-md font-medium">{{user?.email}}</span>
        </span>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button mat-menu-item [routerLink]="['/settings']" [queryParams]="{tabIndex: 3}">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        <span>Profile</span>
    </button>
    <button mat-menu-item [routerLink]="['/settings']" [queryParams]="{tabIndex: 0}">
        <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
        <span>Settings</span>
    </button> -->
    <!-- <button
        mat-menu-item
        [matMenuTriggerFor]="userStatus">
        <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
        <span>Status</span>
    </button> -->
    <!-- <mat-divider class="my-2"></mat-divider> -->
    <button mat-menu-item [routerLink]="['/account']" class="text-white">
        <mat-icon svgIcon="heroicons_outline:user" class="text-white"></mat-icon>
        <span>Account</span>
    </button>
    <a mat-menu-item href="https://cacius.zohodesk.com/portal/en/home" target="_blank" class="text-white">
        <mat-icon svgIcon="support_agent" class="text-white"></mat-icon>
        <span>Support</span>
    </a>
    <button mat-menu-item (click)="signOut()" class="text-white">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'" class="text-white"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>

<!-- <mat-menu class="user-status-menu" #userStatus="matMenu">
    <button mat-menu-item (click)="updateUserStatus('online')">
        <span class="w-4 h-4 mr-3 rounded-full bg-green-500"></span>
        <span>Online</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('away')">
        <span class="w-4 h-4 mr-3 rounded-full bg-amber-500"></span>
        <span>Away</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('busy')">
        <span class="w-4 h-4 mr-3 rounded-full bg-red-500"></span>
        <span>Busy</span>
    </button>
    <button mat-menu-item (click)="updateUserStatus('not-visible')">
        <span class="w-4 h-4 mr-3 rounded-full bg-gray-400"></span>
        <span>Invisible</span>
    </button>
</mat-menu> -->