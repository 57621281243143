import { inject, Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({providedIn: 'root'})
export class IconsService
{
    /**
     * Constructor
     */
    constructor()
    {
        const domSanitizer = inject(DomSanitizer);
        const matIconRegistry = inject(MatIconRegistry);

        // Register icon sets
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-twotone.svg'));
        matIconRegistry.addSvgIconSetInNamespace('mat_outline', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-outline.svg'));
        matIconRegistry.addSvgIconSetInNamespace('mat_solid', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/material-solid.svg'));
        matIconRegistry.addSvgIconSetInNamespace('feather', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/feather.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_outline', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-outline.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_solid', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-solid.svg'));
        matIconRegistry.addSvgIconSetInNamespace('heroicons_mini', domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/heroicons-mini.svg'));
        matIconRegistry.addSvgIcon('attachReports', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/attach_reports.svg'));
        matIconRegistry.addSvgIcon('documentIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/document-icon.svg'));
        matIconRegistry.addSvgIcon('modalCrossIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/error-cross-icon.svg'));
        matIconRegistry.addSvgIcon('modalSuccessIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/success-cross-icon.svg'));
        matIconRegistry.addSvgIcon('infoIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/info-icon.svg'));
        matIconRegistry.addSvgIcon('filterIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/filter.svg'));
        matIconRegistry.addSvgIcon('reportIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/reportIcon.svg'));
        matIconRegistry.addSvgIcon('oneReport', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/oneReport.svg'));
        matIconRegistry.addSvgIcon('threeReport', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/threeReport.svg'));
        matIconRegistry.addSvgIcon('sendIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/sendIcon.svg'));
        matIconRegistry.addSvgIcon('chipIcon', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/chipIcon.svg'));
        matIconRegistry.addSvgIcon('downArrow', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/downArrow.svg'));
        matIconRegistry.addSvgIcon('viewReport', domSanitizer.bypassSecurityTrustResourceUrl('./assets/icons/view-report-icon.svg'));










    }
}
