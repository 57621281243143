export const studyLog = [
    {
        id: '123456789SL1',
        statusText: 'Accessed',
        statusColor: 'green',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'FR',
        details: 'Accessed study'
    },
    {
        id: '123456789SL2',
        statusText: 'Uploaded',
        statusColor: 'blue',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'RS',
        details: 'Uploaded image, File Format: jpg, File Size: 2.5 MB, Time: 03:00pm'
    },
    {
        id: '123456789SL3',
        statusText: 'Downloaded',
        statusColor: 'grey',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Downloaded image, File Format: pdf, File Size: 3.1 MB, Time: 03:00p'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },

    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    },
    {
        id: '123456789SL4',
        statusText: 'Edited',
        statusColor: 'yellow',
        mrn: '0018247365',
        exam: 'Dental radiography (dental X-rays)',
        submit_date: '09-18-2024',
        user_name: 'Tom Holland',
        role: 'PR',
        details: 'Modifications: Added annotations and adjusted brightness'
    }
]