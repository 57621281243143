import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { layoutChangeType } from './header.type';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private layoutState: BehaviorSubject<layoutChangeType> = new BehaviorSubject({ flag: 'default', data: null, backFuncRef: null });

  constructor() { }


  /**
    * Getter for alert
    */
  get layoutState$(): Observable<layoutChangeType> {
    return this.layoutState.asObservable();
  }

  /*
      * setter for alert
      */
  setLayoutState(data: layoutChangeType): void {
    this.layoutState.next(data);
  }

  // this is to partially update the data being shown on header.
  updateLayoutState(data: any) {
    const current = this.layoutState.value;
    this.layoutState.next({
      ...current,
      data: {
        ...current.data,
        ...data
      }
    });
  }
}
