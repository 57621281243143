import { Inject, Injectable } from '@angular/core';
import { FuseMockApiService } from '@fuse/lib/mock-api';
import { cloneDeep } from 'lodash';
import { doctors, examsList as examsData, modalities} from './data';
import { Doctors, Modality,User } from 'app/modules/admin/exams/exams.types';

@Injectable({ providedIn: 'root' })
export class ExamsMockApi {
    private _exams: any[] = examsData;
    private _modalities:any[] = modalities;
    private _doctors:Doctors[] = doctors;
    


    // private _fuseMockApiService = Inject(FuseMockApiService);

    /**
     * Constructor
     */
    constructor(private _fuseMockApiService: FuseMockApiService) {
        // Register Mock API handlers
        this.registerHandlers();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Register Mock API handlers
     */
    registerHandlers(): void {
        // -----------------------------------------------------------------------------------------------------
        // @ exams - GET
        // -----------------------------------------------------------------------------------------------------
        this._fuseMockApiService.onGet('api/apps/exams').reply(() => {
            // Clone the Exam
            const exams = cloneDeep(this._exams);
            // Sort the exams alphabetically by id
            exams.sort((a, b) => a.id.localeCompare(b.id));
            return [200, exams];
        });

        this._fuseMockApiService
            .onPost('api/apps/exams/details')
            .reply((req: any) => {
                console.log(req.request.body);
                const id = req.request.body.id;
                // Clone the Exam
                const exams = cloneDeep(this._exams);
                const examDetails = exams.filter((exam) => exam.id === id);
                return [200, examDetails];
            });
            this._fuseMockApiService.onGet('api/apps/exams/modality').reply(() => {
                // Clone the modalities
                const modalities = cloneDeep(this._modalities);
                // Sort the modalities alphabetically by id
                modalities.sort((a, b) => a.modality_id.localeCompare(b.modality_id));
                return [200, modalities];
            });
            this._fuseMockApiService.onGet('api/apps/exams/doctors').reply(() => {
                // Clone the modalities
                const doctors = cloneDeep(this._doctors);
                // Sort the doctors alphabetically by id
                doctors.sort((a, b) => a.doctor_id.localeCompare(b.doctor_id));
                return [200, doctors];
            });
            
    }
}
